import * as ACTION from '../actions/actionTypes';

const INITIAL_STATE = {
  assignmentInfo: null,
  assignedTaskIds: [], // holds last order of assigned task ids
};

export default function assignments(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ACTION.SET_USER_INFO:
    case ACTION.UPDATE_PROJECTS:
    case ACTION.SET_USER_ASSIGNMENTS:
      return action.assignments;

    default:
      return state;
  }
}

import { v4 as genUUID } from 'uuid';
import * as utils from '../utils/generalUtilities.js';
import * as STATUS from '../constants/status.js';
import * as TASK from '../constants/tasks';

export const getBaseStatus = () => {
  return [
    { status: STATUS.NOT_STARTED, active: true },
    { status: STATUS.IN_PROGRESS, active: true },
    { status: STATUS.BLOCKED, active: true },
    { status: STATUS.IN_REVIEW, active: true },
    { status: STATUS.DONE, active: true },
    { status: STATUS.NOT_NEEDED, active: true },
  ];
};
const forceUpdate = false;
const SCHEMA_VERSION = '230509';
// added project base line dates

// const SCHEMA_VERSION = '230214';
// convert tasks to links - deprecated embedded attachments

// const SCHEMA_VERSION = '230109';
// updated all task statuses

// const SCHEMA_VERSION = '220316';
// added pin info to all components

// const SCHEMA_VERSION = '220308';
// deprecated notes/help, added description and templateRefUUID for template-linked advice

// const SCHEMA_VERSION = '220215';
// added added template accessRules

// const SCHEMA_VERSION = '210610';
// added num tasks to WPs/Del and projects

// const SCHEMA_VERSION = '210609';
// ensure all refId's are correct in a project/workflow

// const SCHEMA_VERSION = '210519';
// adding componentLibraryUUID to projects if not existing

// const SCHEMA_VERSION = '210405';
// // updating Questions to Tasks and adding tags/lastModTS/roles/assignee to Del/WPs

// const SCHEMA_VERSION = '200423';
// fixing undefined date values in projects

// const SCHEMA_VERSION = '200429';
// Adding isPriority to deliverables

// const SCHEMA_VERSION = '200817';
// Adding project deletedDate date field

// const SCHEMA_VERSION = '201110';
// Adding attachments to schema

// const SCHEMA_VERSION = '210111';
// Adding attachments to schema

const taskSchema = {
  uuid: genUUID(),
  assignedTo: '',
  help: 'Add task-related guidance here...',
  name: 'a new task',
  note: '', // deprecated, now description as 220309
  richTextNote: null, // deprecated, now description as 220309
  description: '', // component description
  advice: null, // component advice (only in template components)
  templateRefUUID: null, // ref to template component used to create this component, {template: <uuid>, component: <uuid>} or 'self' for self ref in templates
  refId: null,
  isPriority: false,
  started: false, // deprecated as 210429 - no longer used - using actualStartDate state instead
  completed: false, // deprecated as 210429 - no longer used - using actualEndDate state instead
  expectedStartDate: '',
  actualStartDate: '',
  expectedEndDate: '',
  actualEndDate: '',
  answerHistory: [], // deprecated as of schema ver 210328 - doesn't seem to be used anymore
  validAnswers: '', // deprecated as of schema ver 210328 - doesn't seem to be used anymore
  answer: '',
  status: STATUS.NOT_STARTED,
  availableStatus: getBaseStatus(),
  lastModTS: new Date().getTime(),
  tags: [],
  pinned: false,
  attachmentLinks: null,
};

const workPackageSchema = {
  uuid: genUUID(),
  assignedTo: '',
  help: 'Add work-package-related guidance here...',
  name: 'a new task group',
  note: '', // deprecated, now description as 220309
  richTextNote: null, // deprecated, now description as 220309
  description: '', // component description
  advice: null, // component advice (only in template components)
  templateRefUUID: null, // ref to template component used to create this component, {template: <uuid>, component: <uuid>} or 'self' for self ref in templates
  refId: null,
  isPriority: false,
  started: false, // deprecated as 210429 - no longer used - using actualStartDate state instead
  completed: false, // deprecated as 210429 - no longer used - using actualEndDate state instead
  expectedStartDate: '',
  actualStartDate: '',
  expectedEndDate: '',
  actualEndDate: '',
  startedDate: '', // deprecated as of schema ver 200309 - replaced by expectedStartDate
  completedDate: '', // deprecated as of schema ver 200309 - replaced by expectedEndDate
  questions: [], // deprecated as of schema ver 210328 - replaced by tasks
  tasks: [taskSchema],
  lastModTS: new Date().getTime(),
  tags: [],
  numDoneTasks: 0,
  numNonSkippedTasks: 0,
  pinned: false,
  attachmentLinks: null,
};

const deliverableSchema = {
  uuid: genUUID(),
  assignedTo: '',
  help: 'Add deliverable-related guidance here...',
  name: 'a new deliverable',
  note: '', // deprecated, now description as 220309
  richTextNote: null, // deprecated, now description as 220309
  description: '', // component description
  advice: null, // component advice (only in template components)
  templateRefUUID: null, // ref to template component used to create this component, {template: <uuid>, component: <uuid>} or 'self' for self ref in templates
  isPriority: false,
  started: false, // deprecated as 210429 - no longer used - using actualStartDate state instead
  completed: false, // deprecated as 210429 - no longer used - using actualEndDate state instead
  expectedStartDate: '',
  actualStartDate: '',
  expectedEndDate: '',
  actualEndDate: '',
  startedDate: '', // deprecated as of schema ver 200309 - replaced by expectedStartDate
  startedActual: '', // deprecated as of schema ver 200309 - replaced by actualStartDate
  completedDate: '', // deprecated as of schema ver 200309 - replaced by expectedEndDate
  completedActual: '', // deprecated as of schema ver 200309 - replaced by actualEndDate
  workPackages: [workPackageSchema],
  lastModTS: new Date().getTime(),
  tags: [],
  numDoneTasks: 0,
  numNonSkippedTasks: 0,
  pinned: false,
  attachmentLinks: null,
};

const attachmentSchema = {
  uuid: genUUID(),
  file: {
    path: 'GE - Saved - Empty State – 1111.png',
  },
  owner: 'shauna.hennessy.griffin@gmail.com',
  parent: '614f1284-13d4-4b29-9328-bcca6f450cd1',
  name: 'GE - Saved - Empty State – 1111.png',
  type: 'project',
  url:
    'https://firebasestorage.googleapis.com/v0/b/project-assistant-beta.appspot.com/o/attachments%2F614f1284-13d4-4b29-9328-bcca6f450cd1%2FGE%20-%20Saved%20-%20Empty%20State%20%E2%80%93%201111.png?alt=media&token=6ae6d6c6-957d-40a0-aa10-77a5b889612b',
  uploadTime: '2020-11-16T17:44:54+01:00',
};

const projectSchema = {
  uuid: genUUID(),
  creator: 'Stephen Stofanak',
  lastModifiedBy: 'a coworker',
  deliverables: [deliverableSchema],
  id: '',
  name: 'New Project',

  // next 5 items are various notes fields
  note: 'notes', // accomplishments
  richTextNote: null, // accomplishments with rich text - only used here? - needed ?, why can't note be this??
  learnings: '', // learnings
  goalsAndObjectives: 'Goals And Objectives', // project plan

  help: 'How was this project initiated? Basic PM Slides\nWho will be the sponsor?\n', // guidance - deprecated as 220309
  description: '', // component description
  advice: null, // component advice (only in template components)
  templateRefUUID: null, // ref to template component used to create this component, {template: <uuid>, component: <uuid>} or 'self' for self ref in templates

  problemOpportunity: 'Presenting problem / Opportunity',
  projectManager: '',
  projectPriorities: '',
  sharedWith: [],
  sponsor: '',
  template: false, // deprecated as of schema ver 210519 - replaced by expectedStartDate
  templateName: 'Standard', // deprecated as of schema ver 210519 - replaced by expectedStartDate
  version: SCHEMA_VERSION,
  baselineStartDate: '',
  baselineEndDate: '',
  expectedStartDate: '',
  actualStartDate: '',
  expectedEndDate: '',
  actualEndDate: '',
  start: '', // deprecated as of schema ver 200309 - replaced by expectedStartDate
  startedActual: '', // deprecated as of schema ver 200309 - replaced by actualStartDate
  end: '', // deprecated as of schema ver 200309 - replaced by expectedEndDate
  completedActual: '', // deprecated as of schema ver 200309 - replaced by actualEndDate
  deletedDate: null, // holds date the project was deleted (null == not deleted/active)
  attachments: [], // deprecated as of schema ver 230214 - replaced by attachmentLinks on a per component level
  refPrefix: null,
  refLastNum: 0, // deprecated as of schema ver 210328 - replaced by two values
  refLastWPNum: 0,
  refLastTaskNum: 0,
  lastModTS: new Date().getTime(),
  workflowUUID: null, // used in templates (only) to identify parent template, projects use templateRefUUID to identify their genesis template
  // could deprecate this as it now duplicates templateRefUUID
  workflowSetId: null, // used in templates (prev named workflows) to hold workflowSetId
  library: null, // used in workflows to hold library components
  accessRules: null, // used in workflows/templates to hold access rules eg {emailEquals:[a,b,c], tagEquals:[d,e,f]}
  firebaseCollection: 'projects', // or 'workflows' if a workflow with library components
  numDoneTasks: 0,
  numNonSkippedTasks: 0,
  pinned: false,
  starterProject: false,
  attachmentLinks: null,
  numWeeksInSprint: 2,
};

function migrateTemplateSchema(workflow) {
  if (workflow === null) {
    console.log(`migrateTemplateSchema(workflow === null)`);
    return null;
  }
  // console.log(`checking template schema for ${workflow.name} ${workflow.version} `);

  delete workflow.corruptData;
  // if (workflow.version && workflow.version > SCHEMA_VERSION)  // data is newer than app, catch in App.js
  if (!forceUpdate && workflow.version && workflow.version >= SCHEMA_VERSION) {
    // data is newer than app, catch in App.js
    // console.log(`    !!!!!!!!!!!`);
    return workflow;
  }
  console.log(
    `migrateTemplateSchema for ${workflow.name} from ${
      workflow.version ? workflow.version : '??'
    } to ${SCHEMA_VERSION}`,
  );

  migrateProjectSchema(workflow, true);
  workflow.version = SCHEMA_VERSION;

  workflow.library.components.tasks.forEach(task => {
    migrateTaskSchema(task, workflow.version, true);
  });
  workflow.library.components.workPackages.forEach(workPackage => {
    migrateWorkPackageSchema(workPackage, workflow.version, true);
  });
  workflow.library.components.deliverables.forEach(deliverable => {
    migrateDeliverableSchema(deliverable, workflow.version, true);
  });
  return workflow;
}

function migrateTaskSchema(task, version, isTemplate = false) {
  if (task === null) {
    console.log(`migrateTaskSchema(task === null)`);
    return null;
  }

  // touch task migrations by version (in order), eg
  if (version && version < '220308') {
    // if(task.description === undefined) {
    if (task.description === undefined) {
      task.description = task.note ? task.note : '';
    }
    if (task.advice === undefined) {
      task.advice = isTemplate ? (task.help ? task.help : '') : null;
    }
    if (forceUpdate || !task.templateRefUUID) {
      task.templateRefUUID = isTemplate ? 'self' : null;
    }
  }
  if (version && version < '220316') {
    if (forceUpdate || !task.pinned) task.pinned = false;
  }
  if (version && version < '230109') {
    task.availableStatus = getBaseStatus();
  }
  // if (version && version <= '200110') {
  //   task.uuid = genUUID();
  // }
}

function migrateWorkPackageSchema(workPackage, version, isTemplate = false) {
  if (workPackage === null) {
    console.log(`migrateWorkPackageSchema(workPackage === null)`);
    return null;
  }
  // touch WP migrations by version (in order), eg
  if (version && version < '220308') {
    // if(workPackage.description === undefined) {
    if (workPackage.description === undefined) {
      workPackage.description = workPackage.note ? workPackage.note : '';
    }
    if (workPackage.advice === undefined) {
      workPackage.advice = isTemplate ? (workPackage.help ? workPackage.help : '') : null;
    }
    if (forceUpdate || !workPackage.templateRefUUID) {
      workPackage.templateRefUUID = isTemplate ? 'self' : null;
    }
  }
  if (version && version < '220316') {
    if (forceUpdate || !workPackage.pinned) workPackage.pinned = false;
  }

  // if (version && version <= '200110') {
  //   workPackage.uuid = genUUID();
  // }

  // then update item's tasks
  workPackage.tasks.forEach(task => {
    migrateTaskSchema(task, version, isTemplate);
  });
}

function migrateDeliverableSchema(deliverable, version, isTemplate = false) {
  if (deliverable === null) {
    console.log(`migrateDeliverableSchema(deliverable === null)`);
    return null;
  }
  // touch Deliverable migrations by version (in order), eg,
  if (version && version < '220308') {
    // if(deliverable.description === undefined) {
    if (deliverable.description === undefined) {
      deliverable.description = deliverable.note ? deliverable.note : '';
    }
    if (deliverable.advice === undefined) {
      deliverable.advice = isTemplate ? (deliverable.help ? deliverable.help : '') : null;
    }
    if (forceUpdate || !deliverable.templateRefUUID) {
      deliverable.templateRefUUID = isTemplate ? 'self' : null;
    }
  }
  if (version && version < '220316') {
    if (forceUpdate || !deliverable.pinned) deliverable.pinned = false;
  }
  // if (version && version <= '200110') {
  //   deliverable.uuid = genUUID();
  // }

  // then update item's workpackages
  deliverable.workPackages.forEach(workPackage => {
    migrateWorkPackageSchema(workPackage, version, isTemplate);
  });
}

function migrateProjectSchema(oldProject = null, isTemplate = false) {
  if (oldProject === null) {
    console.log(`migrateProjectSchema(project === null)`);
    return null;
  }

  // console.log(`checking project schema for ${oldProject.name} ${oldProject.version} `);

  if (!oldProject.numWeeksInSprint) {
    if (!isTemplate) {
      console.log('  ======= setting project default numWeeksInSprint to 2');
    }
    oldProject.numWeeksInSprint = 2; // as of July 26, 2023
  }
  delete oldProject.corruptData;

  // if (oldProject.version && oldProject.version > SCHEMA_VERSION) // data is up to date or newer than app, catch in App.js
  if (!forceUpdate && oldProject.version && oldProject.version >= SCHEMA_VERSION) {
    // data is up to date or newer than app, catch in App.js
    // console.log(`    !!!!!!!!!!!`);
    return oldProject;
  }
  if (!isTemplate) {
    console.log(
      `migrateProjectSchema for ${oldProject.name} from ${
        oldProject.version ? oldProject.version : '??'
      } to ${SCHEMA_VERSION}`,
    );
  }
  // upgrading 200110 to 200203
  // adding UUID to all objects
  if (oldProject.version && oldProject.version === '200110') {
    if (!oldProject.uuid) oldProject.uuid = genUUID(); // eg '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'

    oldProject.deliverables.forEach(deliverable => {
      if (!deliverable.uuid) deliverable.uuid = genUUID();

      deliverable.workPackages.forEach(workPackage => {
        if (!workPackage.uuid) workPackage.uuid = genUUID();

        workPackage.questions.forEach(task => {
          if (!task.uuid) task.uuid = genUUID();
        });
      });
    });
    oldProject.version = '200203';
  }

  // upgrading 200203 to 200309
  // standardizing start and end date field names across objects
  // adding richTextNote field
  // added lastModifiedBy field to projects
  // added workPackage isPriority field
  if (oldProject.version && oldProject.version === '200203') {
    if (!oldProject.uuid) oldProject.uuid = genUUID(); // eg '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
    if (!oldProject.richTextNote) oldProject.richTextNote = null;
    if (!oldProject.lastModifiedBy) oldProject.lastModifiedBy = 'a coworker';

    oldProject.expectedStartDate = oldProject.start ? oldProject.start : '';
    oldProject.actualStartDate = oldProject.startedActual ? oldProject.startedActual : '';
    oldProject.expectedEndDate = oldProject.end ? oldProject.end : '';
    oldProject.actualEndDate = oldProject.completedActual ? oldProject.completedActual : '';

    oldProject.deliverables.forEach(deliverable => {
      if (!deliverable.richTextNote) deliverable.richTextNote = null;
      deliverable.expectedStartDate = deliverable.startedDate ? oldProject.start : '';
      deliverable.actualStartDate = deliverable.startedActual ? oldProject.start : '';
      deliverable.expectedEndDate = deliverable.completedDate ? oldProject.start : '';
      deliverable.actualEndDate = deliverable.completedActual ? oldProject.start : '';

      deliverable.workPackages.forEach(workPackage => {
        if (!workPackage.richTextNote) workPackage.richTextNote = null;
        if (!workPackage.isPriority === undefined) workPackage.isPriority = false;
        workPackage.expectedStartDate = workPackage.startedDate ? oldProject.startedDate : '';
        workPackage.actualStartDate = workPackage.started
          ? workPackage.startedDate
            ? oldProject.startedDate
            : ''
          : '';
        workPackage.expectedEndDate = workPackage.completedDate ? oldProject.completedDate : '';
        workPackage.actualEndDate = workPackage.completed
          ? workPackage.completedDate
            ? oldProject.completedDate
            : ''
          : '';

        workPackage.questions.forEach(task => {
          if (!task.richTextNote) task.richTextNote = null;
        });
      });
    });
    oldProject.version = '200309';
  }

  // upgrading 200309 to 200331
  // standardizing start and end date field names across objects
  // adding richTextNote field
  // added lastModifiedBy field to projects
  // added workPackage isPriority field
  if (oldProject.version && oldProject.version === '200309') {
    oldProject.deliverables.forEach(deliverable => {
      deliverable.workPackages.forEach(workPackage => {
        if (!workPackage.isPriority) workPackage.isPriority = false;
      });
    });
    oldProject.version = '200331';
  }

  // upgrading 200331 to 200417
  // fixing redundant uuids in workpackages, deliverables and projects (forcing new uuids on all items)
  // adding missing wp.isPriority for previous error in ver 200203 schema update
  if (oldProject.version && oldProject.version === '200331') {
    oldProject.uuid = genUUID();
    oldProject.deliverables.forEach(deliverable => {
      deliverable.uuid = genUUID();
      deliverable.workPackages.forEach(workPackage => {
        workPackage.uuid = genUUID();
        if (!workPackage.isPriority) workPackage.isPriority = false;
        workPackage.questions.forEach(task => {
          task.uuid = genUUID();
        });
      });
    });
    oldProject.version = '200417';
  }

  // upgrading 200417 to 200423
  // fixing undefined date values in projects
  if (oldProject.version && oldProject.version === '200417') {
    oldProject.expectedStartDate = oldProject.expectedStartDate || '';
    oldProject.actualStartDate = oldProject.actualStartDate || '';
    oldProject.expectedEndDate = oldProject.expectedEndDate || '';
    oldProject.actualEndDate = oldProject.actualEndDate || '';

    oldProject.deliverables.forEach(deliverable => {
      deliverable.expectedStartDate = deliverable.expectedStartDate || '';
      deliverable.actualStartDate = deliverable.actualStartDate || '';
      deliverable.expectedEndDate = deliverable.expectedEndDate || '';
      deliverable.actualEndDate = deliverable.actualEndDate || '';

      deliverable.workPackages.forEach(workPackage => {
        workPackage.expectedStartDate = workPackage.expectedStartDate || '';
        workPackage.actualStartDate = workPackage.actualStartDate || '';
        workPackage.expectedEndDate = workPackage.expectedEndDate || '';
        workPackage.actualEndDate = workPackage.actualEndDate || '';
      });
    });
    oldProject.version = '200423';
  }

  // upgrading 200423 to 200429
  // add isPriority to Deliverables
  if (oldProject.version && oldProject.version === '200423') {
    oldProject.deliverables.forEach(deliverable => {
      if (!deliverable.isPriority) deliverable.isPriority = false;
    });
    oldProject.version = '200429';
  }

  // upgrading 200429 to 200817
  // add deletedDate to project
  if (oldProject.deletedDate === undefined) {
    oldProject.deletedDate = null;
    oldProject.version = '200817';
  }

  // upgrading 200817 to 201110
  // add attachments to projects
  if (oldProject.version && oldProject.version === '200817') {
    oldProject.attachments = oldProject.attachments || [];
    oldProject.version = '201110';
  }

  // upgrading 201110 to 210111
  // add attachments to projects
  if (oldProject.version && oldProject.version === '201110') {
    oldProject.refPrefix = utils.genProjectID(oldProject.name);
    oldProject.refLastNum = 0;

    oldProject.deliverables.forEach(deliverable => {
      deliverable.workPackages.forEach(workPackage => {
        workPackage.refId = utils.genWPRefId(oldProject);
      });
    });

    oldProject.version = '210111';
  }

  // upgrading 210111 to 210328
  // exand WP tasks,
  // rename questions to tasks,
  // add tags and lastModTS to Del,WPs
  // project rename refLastNum to refLastWPNum and add refLastTaskNum
  if (oldProject.version && oldProject.version === '210111') {
    if (!oldProject.refPrefix || oldProject.refPrefix === '')
      oldProject.refPrefix = utils.genProjectID(oldProject.name);
    oldProject.lastModTS = new Date().getTime();
    oldProject.refLastWPNum = oldProject.refLastNum ? oldProject.refLastNum : 0;
    oldProject.refLastTaskNum = 0;
    delete oldProject.refLastNum;

    oldProject.deliverables.forEach(deliverable => {
      deliverable.lastModTS = new Date().getTime();
      deliverable.tags = [];
      deliverable.workPackages.forEach(workPackage => {
        workPackage.lastModTS = new Date().getTime();
        workPackage.tags = [];
        workPackage.tasks = [];
        if (!workPackage.refId) workPackage.refId = utils.genWPRefId(oldProject);
        workPackage.questions.forEach(question => {
          let newTask = Object.assign({ ...taskSchema }, question);
          if (!newTask.refId) {
            newTask.refId = utils.genTaskRefId(oldProject);
          }
          // note, as part of a UI experiment in early July 2020, old answer values of yes === 'done' in the UI
          // and no === 'begun' and later ==='skip'
          if (newTask.answer === TASK.ANSWER_NO) {
            // begun
            newTask.status = STATUS.IN_PROGRESS;
            newTask.expectedStartDate = workPackage.expectedStartDate;
            newTask.actualStartDate = workPackage.actualStartDate;
          }
          if (newTask.answer === TASK.ANSWER_YES) {
            // done
            newTask.status = STATUS.DONE;
            newTask.availableStatus = getBaseStatus();
            newTask.expectedStartDate = workPackage.expectedStartDate;
            newTask.actualStartDate = workPackage.actualStartDate;
            newTask.expectedEndDate =
              workPackage.expectedEndDatework && workPackage.expectedEndDate !== ''
                ? workPackage.expectedEndDate
                : utils.getNowFormatted();
            newTask.actualEndDate =
              workPackage.actualEndDate && workPackage.actualEndDate !== ''
                ? workPackage.actualEndDate
                : utils.getNowFormatted();
          }
          if (newTask.answer === TASK.ANSWER_LATER) {
            // skipped
            newTask.status = STATUS.NOT_NEEDED;
          }
          delete newTask.answer;
          delete newTask.answerHistory;
          delete newTask.validAnswers;
          workPackage.tasks.push(newTask);
        });
        delete workPackage.questions;
      });
    });

    oldProject.version = '210328';
  }
  // upgrading 210328 to 210405
  // fix bad wp ref numbering,
  // ensure that project refPrefix is set
  if (oldProject.version && oldProject.version === '210328') {
    let firstTime = true;
    if (!oldProject.refPrefix || oldProject.refPrefix === '')
      oldProject.refPrefix = utils.genProjectID(oldProject.name);
    oldProject.deliverables.forEach(deliverable => {
      deliverable.workPackages.forEach(workPackage => {
        if (!workPackage.refId.endsWith('NaN')) {
          if (firstTime) {
            oldProject.refLastWPNum = 0;
            oldProject.refLastTaskNum = 0;
            firstTime = false;
          }
          workPackage.refId = utils.genWPRefId(oldProject);
          workPackage.tasks.forEach(task => {
            task.refId = utils.genTaskRefId(oldProject);
          });
        }
      });
    });
    oldProject.version = '210519';
  }

  // upgrading 210405 to '210519'
  if (oldProject.version && oldProject.version <= '210519') {
    // update an project props...
    if (!oldProject.library) {
      oldProject.workflowSetId = null;
      oldProject.library = null;
      oldProject.workflowUUID = null; // will need to update this somewhere
      oldProject.firebaseCollection = 'projects';
    }
    oldProject.version = '210519';
  }

  // upgrading 210519 to 210609
  // ensure all refId's are properly set
  if (oldProject.version && oldProject.version === '210519') {
    let firstTime = true;
    oldProject.deliverables.forEach(deliverable => {
      deliverable.workPackages.forEach(workPackage => {
        if (workPackage.refId.endsWith('NaN')) {
          // console.log(`converting workpackage NaN ref id: ${workPackage.name} ${workPackage.refId}`)
          if (firstTime) {
            oldProject.refLastWPNum = 0;
            oldProject.refLastTaskNum = 0;
            firstTime = false;
          }
          workPackage.refId = utils.genWPRefId(oldProject);
          workPackage.tasks.forEach(task => {
            task.refId = utils.genTaskRefId(oldProject);
          });
        }
      });
    });
    oldProject.version = '210609';
  }

  // upgrading 210609 to 210610
  // add numtasks to WP, del and Projects
  if (oldProject.version && oldProject.version === '210609') {
    if (!oldProject.numTasks) {
      oldProject.numDoneTasks = 0;
      oldProject.numNonSkippedTasks = 0;
    }
    oldProject.deliverables.forEach(deliverable => {
      if (!deliverable.numTasks) {
        deliverable.numDoneTasks = 0;
        deliverable.numNonSkippedTasks = 0;
      }
      deliverable.workPackages.forEach(workPackage => {
        if (!workPackage.numTasks) {
          workPackage.numDoneTasks = 0;
          workPackage.numNonSkippedTasks = 0;
        }
      });
    });
    oldProject.version = '210610';
  }
  // upgrading 210610 to 220215
  if (oldProject.version && oldProject.version === '220215') {
    if (isTemplate) {
      // if a template
      oldProject.accessRules = { emailEquals: [], tagEquals: [] }; // initialize that all group members have template access
    }
    oldProject.version = '220215';
  }
  // upgrading 220215 to 220308
  if (oldProject.version && oldProject.version < '220308') {
    // if (oldProject.version && oldProject.version <= '220308') {
    if (!oldProject.accessRules) {
      // needed to duplicate this as prev schema change didn't always take
      oldProject.accessRules = isTemplate ? { emailEquals: [], tagEquals: [] } : null; // initialize that all group members have template access
    }
    // if(oldProject.description === undefined) {
    if (oldProject.description === undefined) {
      oldProject.description = oldProject.help ? oldProject.help : '';
    }
    if (oldProject.advice === undefined) {
      oldProject.advice = isTemplate ? (oldProject.help ? oldProject.help : '') : null;
    }
    if (forceUpdate || oldProject.templateRefUUID === undefined) {
      oldProject.templateRefUUID = isTemplate ? 'self' : null;
    }
    oldProject.deliverables.forEach(deliverable => {
      migrateDeliverableSchema(deliverable, oldProject.version, isTemplate);
    });
  }

  if (oldProject.version && oldProject.version < '220316') {
    if (forceUpdate || !oldProject.pinned) oldProject.pinned = false;
  }

  if (oldProject.version && oldProject.version < '230109') {
    if (oldProject.attachments) {
      oldProject.attachments.forEach(attachment => {
        // go find parent object
        let foundObject;
        oldProject.deliverables.forEach(deliverable => {
          if (attachment.parent === deliverable.uuid) foundObject = deliverable;
          deliverable.workPackages.forEach(workPackage => {
            if (attachment.parent === workPackage.uuid) foundObject = workPackage;
            workPackage.tasks.forEach(task => {
              if (attachment.parent === task.uuid) foundObject = task;
            });
          });
        });
        if (attachment.type === 'project') foundObject = oldProject;
        if (foundObject) {
          let objType = 'task';
          if (foundObject.deliverables) objType = 'project';
          if (foundObject.workPackages) objType = 'deliverable';
          if (foundObject.tasks) objType = 'task group';

          console.log(`   =====> moving attachment ${attachment.name} to ${objType} ${foundObject.name}`);
          // map old info onto new format
          const newFile = {
            // to match MS Graph API data format
            uuid: attachment.uuid,
            name: attachment.name,
            type: 'unknown',
            lastModifiedDateTime: attachment.uploadTime ?? new Date().getTime(),
            webURL: attachment.url,
            downloadURL: attachment.url,
            attachedBy: attachment.owner,
            attachedDate: attachment.uploadTime,
            attachedTo: attachment.parent,
            dataSource: 'file upload',
          };
          if (foundObject.attachmentLinks) {
            foundObject.attachmentLinks.push(newFile);
          } else {
            foundObject.attachmentLinks = [newFile];
          }
        }
      });
      // oldProject.attachments = []  // can leave in and be deprecated
    }
  }

  if (oldProject.version && oldProject.version < '230214') {
    oldProject.deliverables.forEach(deliverable => {
      migrateDeliverableSchema(deliverable, oldProject.version, isTemplate);
    });
  }
  if (oldProject.version && oldProject.version === SCHEMA_VERSION) {
    return oldProject;
  }

  if (forceUpdate || (oldProject.version && oldProject.version < SCHEMA_VERSION)) {
    oldProject.baselineStartDate =
      oldProject.actualStartDate !== '' ? oldProject.actualStartDate : oldProject.expectedStartDate;
    oldProject.baselineEndDate =
      oldProject.actualStartDate !== '' ? oldProject.actualEndDate : oldProject.expectedEndDate;
    utils.refreshStats(oldProject);
    oldProject.version = SCHEMA_VERSION;
  }
  if (oldProject.version && oldProject.version === SCHEMA_VERSION) {
    return oldProject;
  }

  console.log(`this project is really old schema - just recreating new project structure`);
  // if it's really old we recreate the project structure
  let newProject = JSON.parse(JSON.stringify(projectSchema));

  // Copy all the values of the project
  Object.getOwnPropertyNames(newProject).forEach(key => {
    if (key !== 'deliverables' && key !== 'version' && oldProject.hasOwnProperty(key))
      newProject[key] = oldProject[key];
  });

  return newProject;
}

export {
  migrateProjectSchema,
  migrateTemplateSchema,
  projectSchema,
  deliverableSchema,
  workPackageSchema,
  taskSchema,
  attachmentSchema,
  SCHEMA_VERSION,
};

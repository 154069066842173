import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import DraftEditor from './styled_components/DraftEditor';

import { updateViewState } from '../data/actions/userInterface';

import * as NAV from '../constants/navigation';
import * as USER from '../constants/user';

const useStyles = makeStyles(theme => ({
  dialog: {},
  dialogContent: {
    marginLeft: '10px',
    marginRight: '10px',
    overflowY: 'hidden',
    // backgroundColor: 'lightBlue',
  },
}));

const labelSet1 = {
  type: 'name',
  title: `Please choose a name for your new Group:`,
  label: `Group Name`,
  defaultInput: `type a name for your group here`,
  b1: 'Connect to Existing Group',
  b2: 'Create New Group',
};

const labelSet2 = {
  type: 'id',
  title: `Enter Existing Group Id:`,
  label: `Group Id`,
  defaultInput: `put existing Group Id here`,
  b1: 'Create New Group',
  b2: 'Connect to Exiting Group',
};

function InfoDialog({ viewState, updateViewState }) {
  const [content, setContent] = React.useState(viewState.infoDialogData);
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={true}
        onClose={() => {
          viewState = Object.assign(viewState, { infoDialogData: null });
          updateViewState(viewState);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false} // turn off auto width logic and apply paperWidthFalse styling
        // classes={{ paperWidthFalse: { width: '80hw', maxWidth: '1000px' } }}
      >
        <DialogTitle style={{ fontWeight: 700 }} id="alert-dialog-title">
          {content.label}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DraftEditor
            label={null}
            value={content.text}
            saveOnChange={content.onSave !== undefined} // if false, autosave on blur
            onSave={result => {
              console.log(`InfoDialog auto save/update from DraftEditor`);
              content.text = result.content;
            }}
            field={content.name}
            small
            fixedWidth={900}
            maxHeight={'100vh'}
            hideToolbar={content.onSave === undefined}
            readOnly={content.onSave === undefined}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              viewState = Object.assign(viewState, { infoDialogData: null });
              updateViewState(viewState);
            }}
            variant="contained"
            color="primary"
          >
            {'Close'}
          </Button>
          {content.onSave && (
            <Button
              onClick={() => {
                console.log(`saving advice content from InfoDialog: ${content.text}`);
                content.onSave(content.text);
                viewState = Object.assign(viewState, { infoDialogData: null });
                updateViewState(viewState);
              }}
              disabled={false}
              variant="contained"
              color="secondary"
            >
              {'save'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default connect(
  state => {
    return {};
  },
  {
    updateViewState,
  },
)(InfoDialog);

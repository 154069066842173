import React, { useEffect } from 'react';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import DraggableAssignmentCard from './DraggableAssignmentCard';
import DraggableAssignmentCard2 from './DraggableAssignmentCard.js';
import DraggableWorkflowCard from './DraggableWorkflowCard';
import * as utils from '../../utils/generalUtilities.js';

// Droppable container for Dragable WP cards
export const DroppableAssignmentsList = ({
  assignments,
  listId = 'LIST',
  workflows, // all
  saveWorkflows,
  templateSetId,
  saveAssignments,
  assignmentListHeight,
}) => {
  const [curAssignments, setCurAssignments] = React.useState(assignments);

  useEffect(() => {
    setCurAssignments(assignments);
  }, [assignments]);

  const onDragEnd = result => {
    // console.log(`drag ended - do something`);
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const newAssignments = [...curAssignments.assignmentInfo];
    const movedAssignment = newAssignments.splice(source.index, 1);
    newAssignments.splice(
      destination.index,
      // source.index < destination.index ? destination.index - 1 : destination.index,
      0,
      movedAssignment[0],
    );
    const assignedTaskIds = newAssignments.reduce((acc, info) => {
      acc.push(info.taskId);
      return acc;
    }, []);
    setCurAssignments({ assignmentInfo: newAssignments, assignedTaskIds: assignedTaskIds });
    saveAssignments(newAssignments);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'assign'} type={'group'}>
        {(dropProvided, dropSnapshot) => {
          // console.log(`rendering DroppableAssignmentsList... `);
          return (
            <div
              ref={dropProvided.innerRef} //
              {...dropProvided.droppableProps}
              style={{
                flex: 0,
                height: `${assignmentListHeight + 200}px`,
                paddingBottom: '600px',
                overflowY: 'scroll',
                // backgroundColor: 'yellow',
              }}
            >
              {curAssignments.assignmentInfo.map((assignment, index) => {
                // {curWorkflowSet.map((project, index) => {
                // console.log(`rendering assignment... ${assignment.taskId}`);
                return (
                  <DraggableAssignmentCard
                    key={`al-${assignment.taskId}`}
                    assignment={assignment}
                    index={index}
                  />
                );
              })}
              {dropProvided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

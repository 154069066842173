import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as NAV from '../constants/navigation';
import * as USER from '../constants/user';
import { updateUserPref } from '../data/actions/firebase/userPreferences';
import { Typography, makeStyles, Link } from '@material-ui/core';
import ReactPlayer from 'react-player';

const onboardingStyles = makeStyles(theme => ({
  dialogTitle: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  hideButton: {
    marginTop: '20px',
  },
  closeButton: {
    marginTop: '20px',
    marginLeft: '20px',
  },
  textCol: {
    display: 'flex',
    flexDirection: 'column',
    alighItems: 'center',
    width: '100%',
  },
  textItem: {
    // color: '#FFF',
    fontSize: 20,
    marginBottom: '10px',
    // textAlign: 'center',
    // fontWeight: 700,
    // textTransform: 'none',
    marginLeft: 15,
    marginRight: 55,
  },
  moreInfo: {
    // color: '#FFF',
    fontSize: 20,
    marginTop: '35px',
    marginBottom: '10px',
    // fontSize: 24,
    // fontWeight: 600,
    // textTransform: 'none',
    marginLeft: 10,
  },
}));

const OnboardingDialog = ({
  title,
  text,
  links,
  textOffset,
  onboardingKey,
  closeDialog,
  viewState,
  userPrefs,
  updateUserPref,
}) => {
  const classes = onboardingStyles();

  const description = [];
  let linkNum = 0;
  const linksLen = links && Array.isArray(links) ? links.length : 0;
  text.forEach((item, index) => {
    const itemBits = item.split('%%link%%');
    const numBits = itemBits.length;
    let itemText = [];

    if (numBits === 1) {
      itemText.push(item);
    } else {
      if (!links || links.length === 0 || linkNum > linksLen - 1) {
        // trap bad data
        throw new Error('Link mismatch text in OnboardingDialog');
      }
      itemBits.forEach((bit, bitIndex) => {
        if (bitIndex !== 0) {
          const url = links[linkNum].link;
          itemText.push(
            <span
              style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                window.open(url, '_blank');
              }}
            >
              {links[linkNum].text}
            </span>,
          );
          linkNum++;
        }
        itemText.push(bit);
      });
    }
    description.push(
      <div className={classes.textCol}>
        <div style={{display: 'flex', flexDirection: 'row', marginLeft: textOffset ?? '40px'}}>
          o
          <Typography className={classes.textItem}>{itemText}</Typography>
        </div>
      </div>
    );
  });

  return (
    <div>
      <Dialog
        open={true}
        onClose={() => {
          if (closeDialog) {
            closeDialog(false);
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // className={classes.dialog}
        PaperProps={{
          style: {
            borderWidth: 3,
            borderRadius: 15,
            borderColor: '#00afa9',
            borderStyle: 'solid',
            padding: '30px',
            minWidth: '650px',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          <Typography variant="h4" align="center">
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
          {onboardingKey !== USER.ONBOARDING_WELCOME_DIALOG ? (
            <Typography className={classes.moreInfo}>{"For more information videos, click on the '?' in the upper right."}</Typography>
          ) : (
            <div  style={{height: '293px', width: '520px', marginTop:"20px", backgroundColor:'green', marginLeft: '40px', border: `3px solid #00afa9`, borderRadius: 5}} >
              {/* <Typography className={classes.moreInfo}>{"For a quick tour."}</Typography> */}
              <ReactPlayer 
                url = {`<${USER.STARTUP_VIDEO_URL}>`}
                // style={{height: '400px', width: '400px', backgroundColor:'red'}}
                height = "293px"
                width = "520px"
                // controls
              />
            </div>
          )
          }
        </DialogContent>
        <DialogActions>
          {(onboardingKey === USER.ONBOARDING_EXAMPLE_PROJECT_DIALOG || onboardingKey === USER.ONBOARDING_WELCOME_DIALOG) ? (
            <Button
              onClick={() => {
                const newOnboarding = Object.assign({}, userPrefs.onboarding);
                delete newOnboarding[onboardingKey];
                let newUserPrefs = Object.assign({}, userPrefs, { onboarding: newOnboarding });
                updateUserPref(newUserPrefs);

                if (closeDialog) {
                  closeDialog(false);
                }
              }}
              variant="contained"
              color="primary"
              className={classes.hideButton}
            >
              Close
            </Button>
          ) : (
            <Fragment>
              <Button
                onClick={() => {
                  const newOnboarding = Object.assign({}, userPrefs.onboarding);
                  delete newOnboarding[onboardingKey];
                  let newUserPrefs = Object.assign({}, userPrefs, { onboarding: newOnboarding });
                  updateUserPref(newUserPrefs);

                  if (closeDialog) {
                    closeDialog(false);
                  }
                }}
                variant="contained"
                color="primary"
                className={classes.hideButton}
              >
                Don't show this again
              </Button>
              <Button
                onClick={() => {
                  if (closeDialog) {
                    closeDialog(false);
                  }
                }}
                variant="contained"
                color="secondary"
                className={classes.closeButton}
              >
                Close
              </Button>
            </Fragment>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connect(
  state => {
    return {
      viewState: state.viewState,
      userPrefs: state.userPrefs,
    };
  },
  {
    updateUserPref,
  },
)(OnboardingDialog);

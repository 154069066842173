// @flow
/* eslint-disable */
import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  withStyles,
  makeStyles,
  Tooltip,
  Badge,
  Typography,
  Menu,
  MenuItem,
  Select,
} from '@material-ui/core';
import AttachFile from '@material-ui/icons/AttachFileOutlined';
import GridOn from '@material-ui/icons/GridOn';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ListIcon from '@material-ui/icons/List';
import SplitWPDialog from './SplitWPDialog';
import SprintColumn from './SprintColumn';
import OnboardingDialog from '../OnboardingDialog';

import { ContentWrapper } from '../styled_components/ContentWrapper';
import { AddButton } from '../styled_components/AddButton';
import ProjectScheduleReport from '../project_overview/ProjectScheduleReport';

import {
  handleMenu,
  reorderColumns,
  handleCardDrop,
  dispatchUpdateViewState,
  setSprintPeriod,
} from '../../data/actions/userInterface';
import { togglePinVisibility, toggleDetailPinVisibility } from '../../data/actions/app';
import { saveCurrentProject, updateViewState } from '../../data/actions/userInterface';
import { dispatchUpdateUsibilityReport } from '../../data/actions/app';

import * as utils from '../../utils/generalUtilities.js';
import * as NAV from '../../constants/navigation';
import * as STATUS from '../../constants/status';
import * as USER from '../../constants/user';
import * as constants from '../../constants/app';
import { TIME_FILTER_1MONTH } from '../../constants/tasks';
import { PriorityHigh } from '@material-ui/icons';

const styles = {
  root: {
    paddingBottom: '8px', // To give some breathing room with the scroll bar
    display: 'flex',
    justifyContent: 'flex-start',
    // overflowX: 'scroll',
    // overflowY: 'hidden',
    overflow: 'hidden',
    maxHeight: 'calc(100% - 120px)',
  },
  collapsed: {
    height: '0',
  },
  addButton: {
    marginLeft: 0,
    marginRight: 20,
    color: '#FFF',
  },
  attachIcon: {
    height: '30px',
    width: '30px',
    border: '2px solid #aaa',
    borderRadius: '5px',
    marginLeft: '30px',
    padding: '2px',
    color: '#888',
    backgroundColor: '#f6f6f6',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
};

const SPRINT_PERIODS = ['1 week', '2 weeks', '4 weeks'];

class SprintView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamModel: false,
      attachmentsOpen: false,
      onboardingDialog: Boolean(
        props.userPrefs.onboarding && props.userPrefs.onboarding[USER.ONBOARDING_SPRINTVIEW_DIALOG],
      ),
      menuOpen: false,
      sprintPeriod: `${props.viewState.numWeeksInSprint} week${
        props.viewState.numWeeksInSprint === 1 ? '' : 's'
      }`,
    };
  }
  setOnboardingDialog = value => {
    this.setState({ onboardingDialog: value });
  };

  onDragEnd = result => {
    const {
      noSprintWPCards,
      sprintData,
      project,
      saveCurrentProject,
      setSprintPeriod,
      viewState,
      dispatchUpdateUsibilityReport,
    } = this.props;

    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    console.log(
      `item drop details - source ${source.droppableId} ${source.index} - ${destination.droppableId} ${destination.index}`,
    );
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }
    let sourceArray;
    if (source.droppableId === constants.NO_SPRINT) {
      sourceArray = noSprintWPCards;
    } else {
      sourceArray = sprintData[source.droppableId];
    }
    let destArray;
    if (destination.droppableId === constants.NO_SPRINT) {
      destArray = noSprintWPCards;
    } else {
      destArray = sprintData[destination.droppableId];
    }

    const destSprintColId = destination.droppableId;
    const destDropOffset =
      destination.droppableId === source.droppableId && destination.index > source.index ? -1 : 0;
    if (
      !utils.setWPSprint(
        project,
        sourceArray[source.index],
        destSprintColId,
        destination.index + (destDropOffset === 0 ? -0.5 : 0.5),
        viewState.numWeeksInSprint,
      )
    ) {
      return;
    }
    // update sprintOrder in WPs
    const moveWPCard = sourceArray.splice(source.index, 1)[0];
    destArray.splice(destination.index + destDropOffset, 0, moveWPCard);
    utils.sprintWPCardsOrderSort(
      destination.droppableId === constants.NO_SPRINT ? noSprintWPCards : sprintData[destination.droppableId],
    );
    saveCurrentProject(project);

    // // reordering column
    // if (result.type === 'COLUMN') {
    //   console.log(`  reordering columns`);
    //   this.props.reorderColumns(source.index, destination.index, false, this.props.zoomDelUID);
    // } else {
    //   console.log(`  reordering cards`);
    //   this.props.handleCardDrop(source, destination, this.props.zoomDelUID ? 'w' : 'p', this.props.viewState, false);
    // }
  };

  render() {
    const {
      classes,
      project,
      withScrollableColumns,
      isCombineEnabled,
      workflows,
      viewState,
      viewStack,
      showListViewDialog,
      noSprintWPCards,
      sprintData,
      zoomDelUID,
      togglePinVisibility,
      toggleDetailPinVisibility,
      setSprintPeriod,
      dispatchUpdateViewState,
    } = this.props;

    const splitWP = viewState.splitWP;
    let pinVisibility = viewState.showUnpinned;
    let changePinVisibility = togglePinVisibility;
    utils.clearAllSelections();

    const sortedActiveSprintIds = Object.keys(sprintData).sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
    let columns = sortedActiveSprintIds.map((sprintId, index) => {
      return (
        <SprintColumn
          key={`wpc-${sprintId}`}
          index={index}
          sprintId={sprintId}
          sprint={sprintData[sprintId]}
          isScrollable={withScrollableColumns}
          isCombineEnabled={isCombineEnabled}
          isExpanded={true}
          showDetail={false}
          viewStack={viewStack}
        />
      );
    });

    return (
      <Fragment>
        <ContentWrapper
          title={NAV.SPRINTVIEW}
          // title={`Schedule`}
          handleMenu={this.props.handleMenu}
          dispatchUpdateViewState={dispatchUpdateViewState}
          viewState={this.props.viewState}
          headerMarginTop={10}
          maxTitleCardHeight={'60px'}
          titleChildren={
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
              <Tooltip title={'view Schedule Report'}>
                <ListIcon
                  // <AssessmentOutlinedIcon
                  className={classes.attachIcon}
                  onClick={e => {
                    // toggleAttachmentsOpen();
                    dispatchUpdateUsibilityReport();
                    Object.assign(viewState, {
                      curWindow: NAV.SCHEDULE_REPORT,
                      lastWindow: { ...viewState.lastWindow, [viewState.curProjectUUID]: NAV.SPRINTVIEW },
                    });
                    dispatchUpdateViewState(viewState);
                  }}
                />
              </Tooltip>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'flex-start',
                  height: '40px',
                  marginRight: '30px',
                }}
              >
                <Typography
                  variant="h6"
                  style={{ alignSelf: 'center', marginRight: '20px', height: '40px' }}
                  noWrap
                >
                  work period
                </Typography>
                {/* <Tooltip title={'set sprint period'}> */}
                <Select
                  id="sprint-period-select"
                  name="sprint-period"
                  // labelId="workpackage-priority-label"
                  classes={{ root: classes.menuItem }}
                  value={this.state.sprintPeriod}
                  onChange={e => {
                    this.setState({ sprintPeriod: e.target.value });
                    setSprintPeriod(parseInt(e.target.value.substring(0, 1)));
                  }}
                  variant="outlined"
                >
                  {SPRINT_PERIODS.map(option => {
                    return (
                      <MenuItem key={`sprint-${option}`} className={classes.menuItem} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
                {/* </Tooltip> */}
              </div>
            </div>
          }
        >
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div
              className={`${classes.root}`}
              aria-expanded={true}
              onScroll={e => {
                if (e.currentTarget.scrollLeft !== viewState.scrollTop.wbsLeft) {
                  if (scrollTimer) {
                    clearTimeout(scrollTimer);
                  }
                  const scrollLeft = e.currentTarget.scrollLeft;
                  scrollTimer = setTimeout(e => {
                    viewState.scrollTop.wbsLeft = scrollLeft;
                    updateViewState(viewState);
                    scrollTimer = undefined;
                  }, 50);
                }
              }}
            >
              <SprintColumn
                key={`wpc-noSprint`}
                index={-1}
                sprintId={constants.NO_SPRINT}
                sprint={noSprintWPCards}
                isScrollable={withScrollableColumns}
                isCombineEnabled={isCombineEnabled}
                isExpanded={true}
                showDetail={false}
                viewStack={viewStack}
              />

              <div
                style={{
                  alignSelf: 'stretch',
                  width: '8px',
                  marginLeft: '-2px',
                  marginRight: '2px',
                  backgroundColor: '#8884',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  overflowY: 'hidden',
                  overflowX: 'scroll',
                }}
              >
                {columns}
              </div>
            </div>
          </DragDropContext>
          {this.state.onboardingDialog && (
            <OnboardingDialog
              title={'The Schedule Screen'}
              text={[
                'Use this screen to schedule your Task Groups that are in progress or have been pinned.',
                'First, select a work period at the top right then drag Task Group cards to the desired work period.',
                'Tap the View Schedule icon at the top for a more detailed report.',
              ]}
              links={null}
              // links={[
              //   { text: 'here', link: 'https://www.youtube.com/watch?v=ebxVHKuNRmI' },
              //   { text: 'here', link: 'https://www.youtube.com/watch?v=xlVpS7cbJN4' },
              // ]}
              // text={"When you log in the Dashboard displays...\nPlease select the  page video now\nGo back to '?' anytime"}
              onboardingKey={USER.ONBOARDING_SPRINTVIEW_DIALOG}
              closeDialog={this.setOnboardingDialog}
              textOffset={'40px'}
            />
          )}
        </ContentWrapper>
        {!splitWP && showListViewDialog && <ProjectScheduleReport />}
        {splitWP && (
          <SplitWPDialog
            parent={'Sprint'}
            project={project}
            // community={user.community}
            sprintData={sprintData}
          />
        )}
      </Fragment>
    );
  }
}

export default connect(
  state => {
    return {
      viewState: state.viewState,
      workflows: state.workflows,
      projects: state.projects,
      firebase: state.firebase,
      user: state.user,
      userPrefs: state.userPrefs,
    };
  },
  {
    handleMenu,
    reorderColumns,
    handleCardDrop,
    updateViewState,
    togglePinVisibility,
    toggleDetailPinVisibility,
    saveCurrentProject,
    dispatchUpdateViewState,
    setSprintPeriod,
    dispatchUpdateUsibilityReport,
  },
)(withStyles(styles)(SprintView));

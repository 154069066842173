/* eslint-disable */

import React, { useEffect } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';

import ProjectInfo from '../components/project_overview/ProjectInfo';
import * as utils from '../utils/generalUtilities.js';
import ProjectPlanPage from './styled_components/ProjectPlanPage';
import DraftEditor from './styled_components/DraftEditor';
import { AttachmentsTable } from './attachments/AttachmentsTable';
import OneDriveFileManager from './styled_components/OneDriveFileManager';
import AnalyticsReports from './AnalyticsReports';
import WBS from './project_deliverables/WBS';
import SprintView from './project_deliverables/SprintView';
import Library from './project_deliverables/Library';
import DetailsSection from './work_package_details/DetailsSection';
import { ContentWrapper } from './styled_components/ContentWrapper';

import { dispatchUpdateUsibilityReport } from '../data/actions/app';

import { dispatchUpdateViewState } from '../data/actions/userInterface';
import * as USER from '../constants/user';
import * as NAV from '../constants/navigation';
import * as constants from '../constants/app';

import { LIB_TASK_CONTAINER_CARD } from '../constants/tasks';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  contentWindow: {
    flex: 1, // rel flex in parent
    display: 'flex', // children flex
    maxHeight: '98vh',
    height: '98vh',
    // backgroundColor: 'blue',
  },
  docContainer: {
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1,
    overflowY: 'auto',
  },
  dualDocRow: {
    flex: 1, // rel flex in parent
    display: 'flex', // children flex
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100vh',
    // backgroundColor: 'blue',
  },
  section: {
    gridColumnStart: 'main',
    minHeight: '30vh',
  },
  sidenav: {
    backgroundColor: theme.palette.secondary.faint,
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
  },

  drawer: {
    display: 'block',
    marginTop: 90,
    width: drawerWidth,
    height: `600px`,
    whiteSpace: 'nowrap',
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowY: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'hidden',
    width: theme.spacing(7) + 1,
  },
  content: {
    flex: 1, // rel flex in parent, since drawer is fixed height/width, this takes all the rest of the space
    display: 'flex', // children flex
    overflow: 'hidden',
    padding: theme.spacing(3),
    paddingRight: 10,
    paddingLeft: 25,
    // backgroundColor: 'yellow',
  },
  selectedDrawerButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    backgroundColor: theme.palette.secondary.menu,
  },
  drawerButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
  },
}));

const DrawerWindow = ({
  user,
  handleProjectInfoChange,
  project,
  viewState,
  dispatchUpdateViewState,
  wbsCardsUpdateTS,
  libCardsUpdateTS,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isWorkflow = viewState.currentProjectType === NAV.TYPE_WORKFLOW;
  const [open] = React.useState(false);
  const [wbsInfo, setWBSInfo] = React.useState(() => {
    let initWBSCards = utils.createWBSCards(project);
    let initSprintData = utils.getSprintData(initWBSCards, viewState.numWeeksInSprint);
    return { wbsCards: initWBSCards, sprintData: initSprintData };
  });
  const [libCards, setLibCards] = React.useState(isWorkflow ? utils.createLibCards(project) : []);

  useEffect(() => {
    console.log(`regenerating project cards...`);
    const newWBSCards = utils.createWBSCards(project);
    const newSprintData = utils.getSprintData(newWBSCards, viewState.numWeeksInSprint);
    setWBSInfo({ wbsCards: newWBSCards, sprintData: newSprintData });

    if (isWorkflow) setLibCards(utils.createLibCards(project));
  }, [wbsCardsUpdateTS, libCardsUpdateTS, viewState.numWeeksInSprint]);

  const isAdmin = user.role === USER.ROLE_SYS_ADMIN;
  const isConsultant = user.role === USER.ROLE_CONSULTANT;

  const isWorkflowProject = !!project.library;
  const isWorkflowLevel0Project = !!project.library && project.library.level === 0;

  const menuIconColor = '666';
  const menuButtons = [
    {
      name: NAV.PROJECT_DOCS,
      icon: <DescriptionOutlinedIcon style={{ flex: 1, color: menuIconColor }} />,
    },
    {
      name: NAV.LIBRARY_BOARD,
      icon: <LibraryBooksOutlinedIcon style={{ flex: 1, color: menuIconColor }} />,
    },
    {
      name: NAV.SPRINTVIEW,
      icon: <CalendarTodayOutlinedIcon style={{ flex: 1, color: menuIconColor }} />,
    },
    // {
    //   name: NAV.DAILY_UPDATE,
    //   icon: <CalendarTodayOutlinedIcon style={{ flex: 1, color: menuIconColor }} />,
    // },
  ];
  if (isWorkflowProject) menuButtons.splice(2, 1); // remove sprint view for workflow projects

  if (!(isAdmin || isConsultant) || !isWorkflowProject) menuButtons.splice(1, 1);
  if (!isWorkflowLevel0Project) {
    menuButtons.splice(1, 0, {
      name: NAV.BOARD,
      icon: <GridOnOutlinedIcon style={{ flex: 1, color: menuIconColor }} />,
    });
  }
  const { help, projectPriorities } = project;
  let curCard;
  let content;
  let dItemInd;
  let skip;
  let wItemInd;
  let tItemInd;
  console.log(`In Drawwindow with ${viewState.curWindow}`);

  switch (viewState.curWindow) {
    // case NAV.DAILY_UPDATE:
    //   content = (
    //     <ContentWrapper title="Daily/Weekly Update">
    //       <DraftEditor
    //         label={''}
    //         onSave={handleProjectInfoChange}
    //         value={projectPriorities}
    //         field="projectPriorities"
    //       />
    //     </ContentWrapper>
    //   );
    //   break;
    default: // default, viewState.curWindow = undefined can happen if user already logged on when upgrading to new UI design
    case NAV.BOARD_DETAIL:
    case NAV.BOARD:
      const wbsStackTop = viewState.wbsStack[viewState.wbsStack.length - 1];
      content = (
        <WBS
          label={NAV.BOARD}
          key={project.uuid}
          cards={wbsInfo.wbsCards.delCards}
          isWorkflow={isWorkflow}
          project={project}
          wbsStack={viewState.wbsStack}
          zoomDelUID={wbsStackTop.zoomDelUID}
          showDetailsDialog={wbsStackTop.screen === NAV.BOARD_DETAIL}
          wbsCards={wbsInfo.wbsCards}
          sprintData={wbsInfo.sprintData['activeSprints']}
        />
      );
      break;
    case NAV.SCHEDULE_REPORT:
    case NAV.SPRINTVIEW:
      content = (
        <SprintView
          label={NAV.SPRINTVIEW}
          key={project.uuid}
          noSprintWPCards={wbsInfo.sprintData[constants.NO_SPRINT]}
          sprintData={wbsInfo.sprintData['activeSprints']}
          project={project}
          viewStack={viewState.wbsStack}
          showListViewDialog={viewState.curWindow === NAV.SCHEDULE_REPORT}
        />
      );
      break;
    case NAV.LIBRARY_BOARD:
      const libView = viewState.libStack[viewState.libStack.length - 1];
      if (libView.screen === NAV.LIBRARY_BOARD) {
        content = <Library label={NAV.LIBRARY_BOARD} key={project.uuid} cards={libCards} />;
      } else {
        dItemInd = libView.item.d;
        skip = false;
        if (dItemInd > libCards.length - 1) {
          dItemInd = libCards.length - 1;
        }
        curCard = libCards[dItemInd];
        wItemInd = libView.item.w;
        tItemInd = libView.item.t;
        if ('d'.includes(libView.level) && libCards.length <= 2) {
          skip = true;
        }
        if ('wt'.includes(libView.level) && wItemInd >= 0) {
          if (wItemInd > curCard.wpCards.length - 1) {
            wItemInd = curCard.wpCards.length - 1;
            skip = true;
          }
          curCard = curCard.wpCards[curCard.cardType === LIB_TASK_CONTAINER_CARD ? tItemInd : wItemInd];
        }
        if (libView.level === 't' && tItemInd >= 0 && dItemInd !== 0) {
          if (tItemInd > curCard.taskCards.length - 1) {
            tItemInd = curCard.taskCards.length - 1;
            skip = true;
          }
          curCard = curCard.taskCards[tItemInd];
        }

        if (skip) {
          content = null; // skip a frame to let cards refresh
        } else {
          content = (
            <DetailsSection
              viewCard={curCard}
              viewStack={viewState.libStack}
              parent={'Library'}
              project={project}
              community={user.community}
              sprintData={wbsInfo.sprintData['activeSprints']}
            />
          );
        }
      }
      break;
    case NAV.PROJECT_DOCS:
      content = <ProjectPlanPage project={project} handleProjectInfoChange={handleProjectInfoChange} />;
      break;
    case NAV.HELP: // currently not used ...
      // content = (<Typography paragraph>'put analytics here'</Typography>)
      content = <ContentWrapper title="Help with Project Assistant">{'help content'}</ContentWrapper>;
      break;
    case NAV.ANALYTICS:
      // content = (<Typography paragraph>'put analytics here'</Typography>)
      content = <AnalyticsReports />;
      break;
    // default:
    //   content = (
    //     <Typography paragraph>
    //       `ERROR: bad window content type ${viewState.curWindow}`
    //     </Typography>
    //   );
  }

  return (
    <div className={classes.contentWindow}>
      <Drawer
        // zIndex={90}
        // style={{marginTop: 160}}
        variant="permanent"
        className={clsx(classes.drawer, {
          // [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            // [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        // containerStyle={{
        //   backgroundColor: theme.palette.secondary.main,
        // }}
      >
        <List className={classes.sidenav}>
          <ListItem button key={'space1'} disabled style={{ height: 73 }} />
          {menuButtons.map((item, index) => {
            let curStackItem;
            let jumpToTop = false;
            return (
              <Tooltip title={item.name} key={`TT-${item.name.substring(0, 5)}-${index}`} placement="right">
                <ListItem
                  button
                  key={`${item.name}-${index}`}
                  onClick={() => {
                    if (item.name === NAV.BOARD) {
                      if (viewState.curWindow === NAV.BOARD || viewState.prevWindow === NAV.SPRINTVIEW) {
                        // if already in board, jump to top level
                        curStackItem = viewState.wbsStack[0];
                        jumpToTop = true;
                      } else {
                        curStackItem = viewState.wbsStack[viewState.wbsStack.length - 1];
                      }
                      Object.assign(viewState, {
                        curWindow: NAV.BOARD,
                        lastWindow: { ...viewState.lastWindow, [viewState.curProjectUUID]: item.name },
                        currentDeliverable: curStackItem.d >= 0 ? curStackItem.d : 0,
                        currentWorkPackage: curStackItem.w >= 0 ? curStackItem.w : 0,
                        currentTask: curStackItem.t >= 0 ? curStackItem.t : 0,
                        wbsStack: jumpToTop ? [curStackItem] : viewState.wbsStack,
                      });
                    } else {
                      if (item.name === NAV.LIBRARY_BOARD) {
                        if (viewState.curWindow === NAV.LIBRARY_BOARD) {
                          // if already in board, jump to top level
                          curStackItem = viewState.libStack[0];
                          jumpToTop = true;
                        } else {
                          curStackItem = viewState.libStack[viewState.libStack.length - 1];
                        }
                        Object.assign(viewState, {
                          curWindow: NAV.LIBRARY_BOARD,
                          lastWindow: { ...viewState.lastWindow, [viewState.curProjectUUID]: item.name },
                          lastWindow: {
                            ...viewState.lastWindow,
                            [viewState.curProjectUUID]: NAV.LIBRARY_BOARD,
                          },
                          currentDeliverable: curStackItem.d >= 0 ? curStackItem.d : 0,
                          currentWorkPackage: curStackItem.w >= 0 ? curStackItem.w : 0,
                          currentTask: curStackItem.t >= 0 ? curStackItem.t : 0,
                          libStack: jumpToTop ? [curStackItem] : viewState.libStack,
                        });
                      } else if (item.name === NAV.SCHEDULE_REPORT) {
                        // schedule report
                        dispatchUpdateUsibilityReport();
                        Object.assign(viewState, {
                          curWindow: item.name,
                          lastWindow: { ...viewState.lastWindow, [viewState.curProjectUUID]: item.name },
                        });
                      } else {
                        // SPRINTVIEW
                        Object.assign(viewState, {
                          curWindow: item.name,
                          lastWindow: { ...viewState.lastWindow, [viewState.curProjectUUID]: item.name },
                          wbsStack: [
                            {
                              screen: NAV.SPRINTVIEW,
                              item: {
                                d: 0,
                                w: -1,
                                t: -1,
                              },
                              level: 'p',
                            },
                          ],
                        });
                      }
                    }
                    dispatchUpdateViewState(viewState);
                  }}
                  // style={{overflow: 'hidden'}}
                  className={
                    item.name === viewState.curWindow ? classes.selectedDrawerButton : classes.drawerButton
                  }
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  {/* <ListItemText primary={item.name} /> */}
                </ListItem>
              </Tooltip>
            );
          })}
          <ListItem button key={'space2'} disabled style={{ height: 1000 }} />
        </List>
        <Divider />
      </Drawer>
      {/* <main className={classes.content}>{content}</main> */}
      <div className={classes.content}>{content}</div>
    </div>
  );
};

export default connect(
  state => {
    return {
      viewState: state.viewState,
      wbsCardsUpdateTS: state.viewState.wbsCardsUpdateTS,
      libCardsUpdateTS: state.viewState.libCardsUpdateTS,
    };
  },
  {
    dispatchUpdateUsibilityReport,
    dispatchUpdateViewState,
  },
)(DrawerWindow);

import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistReducer, persistStore, createMigrate } from 'redux-persist';
import localForage from 'localforage';
import thunk from 'redux-thunk';

// ------------------------
// import all sub-reducers
// ------------------------
// app state
import projects from './reducers/projects';
import workflows from './reducers/workflows';
import viewState from './reducers/viewState';
import user from './reducers/user';
import firebase from './reducers/firebase';
import app from './reducers/app';
import commandString from './reducers/commandString';
import analytics from './reducers/analytics';
import session from './reducers/session';
import userPrefs from './reducers/userPrefs';
import onboarding from './reducers/onboarding';
import usibilityReport from './reducers/usibilityReport';
import productPlans from './reducers/productPlans';
import group from './reducers/group';
import sso from './reducers/sso';
import assignments from './reducers/assignments';
import msAzureAPI from './reducers/msAzureAPI';

const root_reducer = combineReducers({
  app,
  projects,
  workflows,
  viewState,
  user,
  firebase,
  commandString,
  analytics,
  session,
  userPrefs,
  onboarding,
  usibilityReport,
  group,
  sso,
  productPlans,
  msAzureAPI,
  assignments,
});

const migrations = {
  0: state => {
    // init migration - do nothing
    return {
      ...state,
    };
  },
  // 1: (state) => {
  //   // migration to keep only device state
  //   return {
  //     device: state.device
  //   }
  // }
};

localForage.config({
  driver: [localForage.INDEXEDDB],
  name: 'PAStorage',
  version: 1.0,
  storeName: 'PAStorage', // Should be alphanumeric, with underscores.
  description: 'storage for PA',
});

//Creates our store with middleware
const persistConfig = {
  key: 'PAroot',
  timeout: 10000,
  storage: localForage,
  version: 0,
  whitelist: ['app', 'projects', 'workflows', 'viewState', 'session', 'onboarding'],
  migrate: createMigrate(migrations, { debug: false }),
};
const persistedReducer = persistReducer(persistConfig, root_reducer);
let store = null;
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  // if chrome or firefox Redux Devtools extension is installed,
  // (see https://github.com/zalmoxisus/redux-devtools-extension#installation)
  // allow extension to see changes to the store
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    realtime: true,
    name: 'ProjectAssistantStore',
    hostname: 'localhost',
    port: 3000,
  });
  console.log('creating redux store with REDUX_DEVTOOLS_EXTENSION wrapper');
  store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
} else {
  store = createStore(persistedReducer, applyMiddleware(thunk));
}
const persistor = persistStore(store);

export { store, persistor };

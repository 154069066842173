import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, MenuItem, Menu, makeStyles } from '@material-ui/core';
import * as utils from '../../utils/generalUtilities';
import { rollUpDates } from '../../data/actions/userInterface';
import { getBaseStatus } from '../../data/projectSchema.js'
import * as STATUS from '../../constants/status';

const buttonStyles = makeStyles(theme => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  colorBase: {
    color: ({ buttonColor }) => theme.palette.getContrastText(buttonColor),
    backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    '&:hover': {
      backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    },
    width: '120px',
  },
}));

const globalStatusValues = getBaseStatus();
const StatusMenu = ({ 
  task, 
  anchorEl, 
  buttonClasses, 
  handleSelect, 
  open, 
  handleClose 
}) => {
  return (
    <Menu
      id={`card-status-menu-${task.refId}`}
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
    >
      {/* {task.availableStatus.map(option => { */}
      {globalStatusValues.map(option => {
        if (option.active) {
          return (
            <MenuItem
              key={STATUS.statusString[option.status]}
              className={buttonClasses.menuItem}
              size={'small'}
              value={STATUS.statusString[option.status]}
              onClick={e => {
                handleSelect(e.target.textContent);
              }}
            >
              {STATUS.statusString[option.status]}
            </MenuItem>
          );
        } else return null;
      })}
    </Menu>
  );
};

const StatusButton = ({ 
  task = null,
  taskCard = null, 
  rollUpDates,
  onChangeFn = null
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  task = task || taskCard.task;

  const buttonColor = utils.getStatusColor(task.status);
  const buttonClasses = buttonStyles({ buttonColor });
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  
  const handleSelect = value => {
    const newStatus = STATUS.statusFromString[value];
    if (task.status !== newStatus) {
      if (newStatus === STATUS.NOT_NEEDED || newStatus === STATUS.NOT_STARTED) {
        task.expectedStartDate = '';
        task.expectedEndDate = '';
      }
      if (newStatus === STATUS.BLOCKED) {
        task.expectedEndDate = '';
      }
      if (newStatus === STATUS.DONE) {
        task.pinned = false;
      }
      task.status = STATUS.statusFromString[value];
      rollUpDates({ start: false, end: true, taskID: task?.uuid });
    }
    if(onChangeFn) {
      onChangeFn(new Date().getTime());
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Button size={'small'} onClick={handleClick} variant="contained" className={buttonClasses.colorBase}>
        {STATUS.statusString[task.status]}
      </Button>
      {open && (
        <StatusMenu
          task={task}
          anchorEl={anchorEl}
          buttonClasses={buttonClasses}
          handleSelect={handleSelect}
          open={open}
          handleClose={handleClose}
        />
      )}
    </Fragment>
  );
};
export default connect(
  state => {
    return {};
  },
  {
    rollUpDates,
  },
)(StatusButton);

/* eslint-disable */

import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import { Typography, Card, CardContent, CardActions, Button } from '@material-ui/core';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import ActionMenu from '../styled_components/ActionMenu';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReplyIcon from '@material-ui/icons/Reply';
import Person from '@material-ui/icons/Person';
import { InfoButton } from '../styled_components/InfoButton';
import { DateRange } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import {
  setUserColleguePref,
  updateViewState,
  handleMenu,
  dispatchUpdateViewState,
  resetProjectSchedule,
  saveCurrentProject,
} from '../../data/actions/userInterface';
import { onboardingUpdate } from '../../data/actions/app';
import { PriorityIcon } from '../Icons/PriorityIcon';
import { red, yellow, grey } from '@material-ui/core/colors';
import * as utils from '../../utils/generalUtilities';
import * as NAV from '../../constants/navigation';
import * as USER from '../../constants/user';

const assignedToAvatarSize = 30;

const useStyles = makeStyles(theme => ({
  card: {
    margin: '10px',
    boxShadow: '4px 4px 4px 0 rgba(138, 148, 159, 0.7)',
    border: `solid 1.px ${theme.palette.workCard.projectBorder}`,
    paddingBottom: '0',
    cursor: 'pointer',
    zIndex: 5,
    background: theme.palette.workCard.notStarted,
    borderColor: theme.palette.workCard.projectBorder,
  },
  cardContent: {
    display: 'flex',
    justifyContent: ({ thin }) => `${thin ? 'flex-start' : 'space-between'}`,
  },
  nameRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  guidanceButtonContainer: {
    marginLeft: 10,
    alignSelf: 'flex-start',
  },
  headerText: {
    fontSize: '1.1rem',
    fontWeight: '700',
  },
  cardCol: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: 15,
  },
  dataRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // backgroundColor: 'yellow'
  },
  thinName: {
    // flex: 1,
    // maxWidth: '50%',
    // backgroundColor: 'red'
    color: ({ isStarted }) => (isStarted ? theme.palette.text.secondary : theme.palette.text.notStarted),
  },
  nameText: {
    color: ({ isStarted }) => (isStarted ? theme.palette.text.secondary : theme.palette.text.notStarted),
  },
  header: {
    fontSize: '1rem',
    fontWeight: '500',
  },
  selected: {
    border: `solid 1.2px ${theme.palette.secondary.main}`,
    borderColor: theme.palette.workCard.selectedBorder,
  },
  started: {
    // background: theme.palette.primary.main,
    background: theme.palette.workCard.started,
    borderColor: theme.palette.workCard.projectBorder,
  },
  completed: {
    // background: theme.palette.secondary.light,
    background: theme.palette.workCard.completed,
    borderColor: theme.palette.workCard.projectBorder,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  chipContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  chip: {
    border: 'solid 1px #E2C250C0',
    background: 'none',
  },
  assignedToAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: assignedToAvatarSize / 2,
    height: assignedToAvatarSize,
    width: assignedToAvatarSize,
  },
  assignedToText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
    // backgroundColor: 'green'
  },
  numTasks: {
    marginLeft: 15,
    textAlign: 'left',
    color: ({ isStarted }) => (isStarted ? theme.palette.text.secondary : theme.palette.text.notStarted),
    // backgroundColor: 'blue'
  },
  percentage: {
    marginLeft: 5,
    textAlign: 'left',
    // backgroundColor: 'blue'
  },
  endDate: {
    marginLeft: 15,
    textAlign: 'left',
    // backgroundColor: 'blue'
  },
  notAssignedTo: {
    color: '#A0A0A040',
  },
  thinActionMenu: {
    marginLeft: 10,
    alignSelf: 'center',
  },
  colorBase: {
    color: ({ buttonColor }) => theme.palette.getContrastText(buttonColor),
    backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    '&:hover': {
      backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    },
    width: '120px',
    marginRight: 15,
  },
  editButton: {
    alignSelf: 'center',
    maxHeight: 25,
    marginLeft: 15,
  },
  subTitle: {
    // flex: 1,
    fontSize: '.9rem',
    // marginLeft: '0px',
    // marginTop: '5px',
    // marginBottom: '5px',
    // color: '#555',
  },
}));

const DraggableProjectCard = ({
  project,
  index,
  isWorkflow,
  showLimitReachedDialog,
  viewState,
  group,
  handleMenu,
  userPrefs,
  user,
  setUserColleguePref,
  dispatchUpdateViewState,
  onboardingUpdate,
  thin = false,
  updateViewState,
  onboarding,
  resetProjectSchedule,
  saveCurrentProject,
}) => {
  const { percentageComplete, actualEndDate, actualStartDate, expectedEndDate, name } = project;
  const theme = useTheme();

  const handleDoubleClick = e => {
    if (project.blockAccess) {
      showLimitReachedDialog(project.blockAccess);
      return;
    }

    const newStack = [
      {
        screen: NAV.BOARD,
        item: {
          d: 0,
          w: -1,
          t: -1,
        },
        level: 'p',
      },
    ];
    const nextWindow = viewState.lastWindow[project.uuid] ? viewState.lastWindow[project.uuid] : NAV.BOARD;
    Object.assign(viewState, {
      currentDeliverable: 0,
      currentWorkPackage: 0,
      currentTask: 0,
      wbsStack: newStack,
      curWindow: nextWindow,
      curProjectUUID: project.uuid,
      lastWindow: { ...viewState.lastWindow, [project.uuid]: nextWindow },
      projectScheduleReport: {
        overdue: false,
        today: false,
        soon: false,
        missing: false,
        justMe: false,
        priority: false,
        statusInProgress: false,
        statusDone: false,
        statusNotStarted: false,
        projectTeams: viewState.projectScheduleReport.projectTeams,
        projectTimeFilters: viewState.projectScheduleReport.projectTimeFilters,
      },
      scrollTop: {
        // reset scrollTop values when opening new project
        dashboardProjects: viewState.scrollTop.dashboardProjects,
        dashboardNeedsAttention: viewState.scrollTop.dashboardNeedsAttention,
      },
    });
    dispatchUpdateViewState(viewState);
    onboarding = Object.assign(
      { ...onboarding },
      {
        seenProjectDoubleClick: true,
      },
    );
    onboardingUpdate(onboarding);
  };

  const isSelected =
    viewState.currentProjectType === NAV.TYPE_PROJECT && index === viewState.currentProjectIndex;
  const buttonColor = '#F00';
  const isStarted = actualStartDate !== '';
  const isCompleted = actualEndDate !== '';
  const classes = useStyles({ thin, isSelected, buttonColor, isStarted, isCompleted });

  const isAdmin = user.role === USER.ROLE_SYS_ADMIN;
  const isConsultant = user.role === USER.ROLE_CONSULTANT;

  let MENU_ACTIONS = [
    {
      name: 'Open', // same as double click -> goto WBS
      action: `project.${index}.Open`,
      icon: <OpenInNewIcon />,
    },
    {
      name: 'Delete',
      action: `project.${index}.Delete`,
      icon: <DeleteIcon />,
    },
    {
      name: 'Copy',
      action: `project.${index}.Copy`,
      icon: <FileCopyIcon />,
    },
  ];
  if ((isAdmin || isConsultant) && group) {
    MENU_ACTIONS.push({
      name: 'Save as Private Workflow',
      action: `project.${index}.PrivateTemplate`,
      icon: <FileCopyIcon />,
    });
  }
  if (isAdmin) {
    MENU_ACTIONS.push({
      name: 'Save as Public Workflow',
      action: `project.${index}.Template`,
      icon: <FileCopyIcon />,
    });
    MENU_ACTIONS.push({
      name: 'Save as Core Smart Workflow',
      action: `project.${index}.CoreTemplate`,
      icon: <FileCopyIcon />,
    });
  }
  const showPercentage = percentageComplete > 0 && actualEndDate === '';

  const handleSelection = (project, index, e) => {
    if (e) e.stopPropagation(); // Necessary to avoid event bubbling up to parent column
    const newStack = [
      {
        screen: NAV.BOARD,
        item: {
          d: 0,
          w: -1,
          t: -1,
        },
        level: 'p',
      },
    ];
    // const projIsNotAWorkflow = project.library === null || project.library === undefined;
    // if (projIsNotAWorkflow) {  // check that referenced workflow still exists !!
    //   const relWorkflow = utils.findWorkflowByUUID(workflows, project.templateRefUUID);
    //   relWorkflowName = relWorkflow ? relWorkflow.name : 'unknown';
    // }
    Object.assign(viewState, {
      currentProjectType: NAV.TYPE_PROJECT,
      currentProjectIndex: index,
      currentDeliverable: 0,
      currentWorkPackage: 0,
      wbsStack: newStack,
      numWeeksInSprint: project.numWeeksInSprint,
    });
    dispatchUpdateViewState(viewState);
    // setSelectedItem(card);
  };

  let newColorAssignment = null;
  useEffect(() => {
    if (newColorAssignment) {
      setUserColleguePref(newColorAssignment);
    }
  });

  const avatarId = project.projectManager ? project.projectManager.substring(0, 1).toUpperCase() : null;
  let collegueColor;
  if (project.projectManager) {
    if (userPrefs.collegues[project.projectManager]) {
      collegueColor = userPrefs.collegues[project.projectManager].color;
    } else {
      collegueColor = utils.randColor();
      newColorAssignment = {
        email: project.projectManager,
        color: collegueColor,
      };
    }
  }
  const numOpenTasks = project.numNonSkippedTasks - project.numDoneTasks;
  const taskName = numOpenTasks === 1 ? 'task' : 'tasks';

  // console.log('card', project.uuid);
  return (
    <div onDoubleClick={handleDoubleClick}>
      <Tooltip title={onboarding.seenProjectDoubleClick ? '' : 'double click to open'}>
        <Card
          key={`card-${project.uuid}`}
          id={project.uuid}
          // ref={dragProvided.innerRef}
          onClick={e => handleSelection(project, index, e)}
          // {...dragProvided.draggableProps}
          // {...dragProvided.dragHandleProps}
          className={`${isSelected ? classes.selected : ''}
              ${classes.card} ${actualEndDate !== '' && !isWorkflow ? classes.completed : ''} ${
            actualStartDate !== '' && !isWorkflow ? classes.started : ''
          }`}
          variant={isSelected ? 'outlined' : 'elevation'}
        >
          <CardContent className={classes.cardContent}>
            <div className={classes.cardCol}>
              <div className={classes.nameRow}>
                <Typography variant="h6" component="h6" className={`${classes.thinName}`} align="left">
                  {name}
                </Typography>
                <InfoButton
                  handleClick={() => {
                    viewState = Object.assign(viewState, {
                      infoDialogData: {
                        name: `Project: ${project.name}`,
                        text: project.description,
                        label: `Description of the Project: ${project.name}`,
                        onSave: newText => {
                          project.description = newText;
                          saveCurrentProject();
                        },
                      },
                    });
                    updateViewState(viewState);
                  }}
                  size="small"
                  color={theme.palette.primary.infoButton}
                  bgdColor="#FFF0"
                  hoverColor={theme.palette.primary.infoButton + '10'}
                  borderColor={theme.palette.primary.infoButton}
                  tooltip={utils.isInfoEmpty(project.description) ? 'add a description' : 'view description'}
                  containerClassName={classes.guidanceButtonContainer}
                  type={'d'}
                />
              </div>
              <div className={classes.dataRow}>
                {isWorkflow ? null : avatarId ? (
                  <Tooltip title={project.projectManager}>
                    <div className={classes.assignedToAvatar} style={{ backgroundColor: collegueColor }}>
                      <Typography className={classes.assignedToText}>{avatarId}</Typography>
                    </div>
                  </Tooltip>
                ) : (
                  <div className={classes.assignedToAvatar} style={{ backgroundColor: '#A0A0A020' }}>
                    <Person className={classes.notAssignedTo} />
                  </div>
                )}

                {true && !isWorkflow && (
                  <div className={classes.numTasks}>
                    <Typography variant="overline" className={classes.subTitle}>
                      {numOpenTasks === 0 ? 'completed' : `${numOpenTasks} ${taskName}`}
                    </Typography>
                  </div>
                )}
                {showPercentage && !isWorkflow && (
                  <div className={classes.percentage}>
                    <Typography variant="overline" className={classes.subTitle}>
                      {`- ${percentageComplete.toFixed(0)}%`}
                    </Typography>
                  </div>
                )}
                {!actualEndDate && expectedEndDate && expectedEndDate !== '' && !isWorkflow && (
                  <Tooltip title={'expected end date'} className={classes.assignedToTooltip}>
                    <div className={classes.endDate}>
                      <Typography variant="overline" className={classes.subTitle}>
                        {`${moment(expectedEndDate).format('MM/DD/YYYY')}`}
                      </Typography>
                    </div>
                  </Tooltip>
                )}
                {actualEndDate && actualEndDate !== '' && !isWorkflow && (
                  <Tooltip title={'actual end date'} className={classes.assignedToTooltip}>
                    <div className={classes.endDate}>
                      <Typography variant="overline" className={classes.subTitle}>
                        {`${moment(actualEndDate).format('MM/DD/YYYY')}`}
                      </Typography>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
            {isSelected && (
              <Button
                size={'small'}
                onClick={() => {
                  viewState = Object.assign(viewState, {
                    curWindow: NAV.PROJECT_OVERVIEW,
                  });
                  updateViewState(viewState);
                }}
                variant="contained"
                className={classes.editButton}
              >
                Overview
              </Button>
            )}
            {isSelected && (
              <div className={classes.thinActionMenu}>
                <ActionMenu handleMenu={handleMenu} actions={MENU_ACTIONS} />
              </div>
            )}
          </CardContent>
        </Card>
      </Tooltip>
    </div>
  );
};
// {/* <Draggable
//   key={`pc-${project.uuid}`} // This is not ideal and it's not a long term solution but creating this since we don't have unique IDs available on cards.
//   draggableId={project.uuid}
//   draggable={false}
//   index={index}
//   shouldRespectForceTouch={false}
// >
//   {(dragProvided, dragSnapshot) => (
// */}

// {/* <) /Draggable> */}

export default connect(
  state => {
    return {
      projects: state.projects,
      viewState: state.viewState,
      userPrefs: state.userPrefs,
      user: state.user,
      onboarding: state.onboarding,
      group: state.group,
    };
  },
  {
    updateViewState,
    handleMenu,
    setUserColleguePref,
    dispatchUpdateViewState,
    onboardingUpdate,
    resetProjectSchedule,
    saveCurrentProject,
  },
)(DraggableProjectCard);

/* eslint-disable */

import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import { Typography, Card, CardContent, CardActions, Button } from '@material-ui/core';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import ActionMenu from '../styled_components/ActionMenu';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import AdjustOutlinedIcon from '@material-ui/icons/AdjustOutlined';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import GroupIcon from '@material-ui/icons/Group';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import Person from '@material-ui/icons/Person';
import TemplateAccessPage from '../TemplateAccessPage';
import { InfoButton } from '../styled_components/InfoButton';
import { DateRange } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import {
  setUserColleguePref,
  updateViewState,
  saveCurrentProject,
  handleMenu,
  dispatchUpdateViewState,
} from '../../data/actions/userInterface';
import { PriorityIcon } from '../Icons/PriorityIcon';
import { red, yellow, grey } from '@material-ui/core/colors';
import * as utils from '../../utils/generalUtilities';
import * as NAV from '../../constants/navigation';
import * as USER from '../../constants/user';
import * as constants from '../../constants/app.js';

const assignedToAvatarSize = 30;

const useStyles = makeStyles(theme => ({
  card: {
    margin: '10px',
    boxShadow: '4px 4px 4px 0 rgba(138, 148, 159, 0.7)',
    border: `solid 1.px ${theme.palette.workCard.projectBorder}`,
    paddingBottom: '0',
    marginLeft: ({ workflow }) => workflow.library.level * 40 + 10,
    cursor: 'pointer',
    zIndex: 5,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    justifyContent: 'space-between',
  },
  nameRow: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  rightSideStuff: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  guidanceButtonContainer: {
    flex: 1,
    marginLeft: 10,
    alignSelf: 'flex-start',
  },
  headerText: {
    fontSize: '1.1rem',
    fontWeight: '700',
  },
  thinName: {
    marginLeft: 15,
    alignSelf: 'center',
  },
  started: {
    background: theme.palette.primary.main,
  },
  header: {
    fontSize: '1rem',
    fontWeight: '500',
  },
  completed: {
    background: theme.palette.secondary.light,
  },
  selected: {
    border: `solid 1.2px ${theme.palette.secondary.main}`,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  chipContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  chip: {
    border: 'solid 1px #E2C250C0',
    background: 'none',
  },
  assignedToAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: assignedToAvatarSize / 2,
    height: assignedToAvatarSize,
    width: assignedToAvatarSize,
    marginLeft: 15,
  },
  assignedToText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
  },
  percentage: {
    minWidth: 50,
    textAlign: 'right',
  },
  assignedToTooltip: {
    fontSize: 20,
  },
  notAssignedTo: {
    color: '#A0A0A040',
  },
  thinActionMenu: {
    marginLeft: 10,
    alignSelf: 'center',
  },
  colorBase: {
    color: ({ buttonColor }) => theme.palette.getContrastText(buttonColor),
    backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    '&:hover': {
      backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    },
    width: '120px',
    marginRight: 15,
  },
}));

const DraggableWorkflowCard = ({
  workflow,
  index,
  templateSetId,
  viewState,
  handleMenu,
  userPrefs,
  user,
  setUserColleguePref,
  dispatchUpdateViewState,
  saveCurrentProject,
  thin = false,
  updateViewState,
  isPrivateList,
}) => {
  const { name, library } = workflow;
  const theme = useTheme();

  const handleDoubleClick = e => {
    const newStack = [
      {
        screen: NAV.BOARD,
        item: {
          d: 0,
          w: -1,
          t: -1,
        },
        level: 'p',
      },
    ];
    const newLibStack = [
      {
        screen: NAV.LIBRARY_BOARD,
        item: {
          d: 0,
          w: -1,
          t: -1,
        },
        level: 'p',
      },
    ];
    Object.assign(viewState, {
      currentDeliverable: 0,
      currentWorkPackage: 0,
      currentTask: 0,
      wbsStack: newStack,
      libStack: newLibStack,
      curWindow: library.level === 0 ? NAV.LIBRARY_BOARD : NAV.BOARD,
      lastWindow: {
        ...viewState.lastWindow,
        [viewState.curProjectUUID]: library.level === 0 ? NAV.LIBRARY_BOARD : NAV.BOARD,
      },
      scrollTop: {
        // reset scrollTop values when opening new project
        dashboardProjects: viewState.scrollTop.dashboardProjects,
        dashboardNeedsAttention: viewState.scrollTop.dashboardNeedsAttention,
      },
    });
    dispatchUpdateViewState(viewState);
  };
  const isSelected =
    viewState.currentProjectType === NAV.TYPE_WORKFLOW &&
    index === viewState.currentWorkflowIndex &&
    templateSetId === viewState.currentWorkflowSetId;
  const buttonColor = '#F00';
  const classes = useStyles({
    thin,
    isSelected,
    buttonColor,
    workflow,
  });
  let MENU_ACTIONS = [
    {
      name: 'Open', // same as double click -> goto WBS
      action: isPrivateList
        ? `workflow.${viewState.currentWorkflowSetId}.${index}.OpenAlways`
        : `workflow.${viewState.currentWorkflowSetId}.${index}.Open`,
      icon: <OpenInNewIcon />,
    },
  ];
  // if (
  //   index !== 0 &&
  //   viewState.currentWorkflowSetId !== constants.PUBLIC_GROUP_ID &&
  //   viewState.currentWorkflowSetId !== constants.CORE_SMART_TEMPLATES_ID
  // ) {
  //   MENU_ACTIONS.push({
  //     name: 'Access',
  //     action: `workflow.${viewState.currentWorkflowSetId}.${index}.Access`,
  //     icon: <GroupIcon />,
  //   });
  // }
  if (library.level !== 0 && !library.default) {
    MENU_ACTIONS.push({
      name: 'Delete',
      action: `workflow.${viewState.currentWorkflowSetId}.${index}.Delete`,
      icon: <DeleteIcon />,
    });
  }
  if (library.level !== 0 && !library.default && workflow.canIndent) {
    MENU_ACTIONS.push({
      name: 'Indent',
      action: `workflow.${viewState.currentWorkflowSetId}.${index}.Indent`,
      icon: <KeyboardTabIcon />,
    });
  }
  if (library.level !== 0 && !library.default && workflow.canOutdent) {
    MENU_ACTIONS.push({
      name: 'Outdent',
      action: `workflow.${viewState.currentWorkflowSetId}.${index}.Outdent`,
      icon: <KeyboardTabIcon style={{ transform: 'rotate(-180deg)' }} />,
    });
  }

  const handleSelection = (workflow, index, e) => {
    if (e) e.stopPropagation(); // Necessary to avoid event bubbling up to parent column
    const newStack = [
      {
        screen: NAV.BOARD,
        item: {
          d: 0,
          w: -1,
          t: -1,
        },
        level: 'p',
      },
    ];
    const newLibStack = [
      {
        screen: NAV.BOARD,
        item: {
          d: -1,
          w: -1,
          t: -1,
        },
        level: 'p',
      },
    ];
    Object.assign(viewState, {
      currentProjectType: NAV.TYPE_WORKFLOW,
      currentWorkflowIndex: index,
      currentWorkflowSetId: templateSetId,
      currentDeliverable: 0,
      currentWorkPackage: 0,
      wbsStack: newStack,
      libStack: newLibStack,
    });
    dispatchUpdateViewState(viewState);
    // setSelectedItem(card);
  };

  let newColorAssignment = null;
  useEffect(() => {
    if (newColorAssignment) {
      setUserColleguePref(newColorAssignment);
    }
  });

  // const avatarId = workflow.projTemplate.projectManager
  //   ? workflow.projTemplate.projectManager.substring(0, 1).toUpperCase()
  //   : null;
  // let collegueColor;
  // if (workflow.projTemplate.projectManager) {
  //   if (userPrefs.collegues[workflow.projTemplate.projectManager]) {
  //     collegueColor =
  //       userPrefs.collegues[workflow.projTemplate.projectManager].color;
  //   } else {
  //     collegueColor = utils.randColor();
  //     newColorAssignment = {
  //       email: workflow.projTemplate.projectManager,
  //       color: collegueColor,
  //     };
  //   }
  // }
  // console.log('card', workflow.uuid);

  return (
    <Draggable
      key={`wc-${workflow.uuid}`}
      draggableId={workflow.uuid}
      draggable={workflow.level > 0}
      isDraggable={workflow.level > 0}
      index={index}
      shouldRespectForceTouch={false}
    >
      {(dragProvided, dragSnapshot) => (
        <div onDoubleClick={handleDoubleClick}>
          <Card
            key={`card-${workflow.uuid}`}
            id={workflow.uuid}
            ref={dragProvided.innerRef}
            onClick={e => handleSelection(workflow, index, e)}
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
            className={`${isSelected ? classes.selected : ''} ${classes.card}`}
            variant={isSelected ? 'outlined' : 'elevation'}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.nameRow}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  component="h6"
                  className={`${classes.thinName}`}
                  align="left"
                >
                  {name}
                </Typography>
                {library.level > 0 && (
                  <InfoButton
                    handleClick={() => {
                      viewState = Object.assign(viewState, {
                        infoDialogData: {
                          name: `Template: ${workflow.name}`,
                          text: workflow.description,
                          label: `Description of the Template: ${workflow.name}`,
                          onSave: newText => {
                            workflow.description = newText;
                            saveCurrentProject();
                          },
                        },
                      });
                      updateViewState(viewState);
                    }}
                    size="small"
                    color={theme.palette.primary.infoButton}
                    bgdColor="#FFF0"
                    hoverColor={theme.palette.primary.infoButton + '10'}
                    borderColor={theme.palette.primary.infoButton}
                    tooltip={
                      utils.isInfoEmpty(workflow.description) ? 'add a description' : 'view description'
                    }
                    containerClassName={classes.guidanceButtonContainer}
                    type={'d'}
                  />
                )}
              </div>
              <div className={classes.rightSideStuff}>
                {isSelected && (
                  <Button
                    size={'small'}
                    onClick={() => {
                      viewState = Object.assign(viewState, {
                        curWindow: NAV.PROJECT_OVERVIEW,
                      });
                      updateViewState(viewState);
                    }}
                    variant="contained"
                    className={classes.editButton}
                  >
                    Overview
                  </Button>
                )}
                {workflow.workflowSetId === constants.PUBLIC_GROUP_ID && library.default && (
                  <Tooltip title={'Default Workflow'}>
                    <AdjustOutlinedIcon style={{ marginTop: 3, marginLeft: 15 }} />
                  </Tooltip>
                )}
                {workflow.workflowSetId === constants.PUBLIC_GROUP_ID && workflow.starterProject && (
                  <Tooltip title={'Starter Project'}>
                    <AcUnitIcon style={{ marginTop: 3, marginLeft: 15 }} />
                  </Tooltip>
                )}
                {!library.visible && (
                  <Tooltip title={'Not visible in List of Workflow'}>
                    <VisibilityOffOutlinedIcon style={{ marginTop: 3, marginLeft: 15 }} />
                  </Tooltip>
                )}
                {isSelected && (
                  <div className={classes.thinActionMenu}>
                    <ActionMenu handleMenu={handleMenu} actions={MENU_ACTIONS} />
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default connect(
  state => {
    return {
      projects: state.projects,
      viewState: state.viewState,
      userPrefs: state.userPrefs,
      user: state.user,
    };
  },
  {
    saveCurrentProject,
    updateViewState,
    handleMenu,
    setUserColleguePref,
    dispatchUpdateViewState,
  },
)(DraggableWorkflowCard);

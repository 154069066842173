/* eslint-disable */

import React, { useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { red, yellow, grey } from '@material-ui/core/colors';

import { useTheme } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Person from '@material-ui/icons/Person';
import Tooltip from '@material-ui/core/Tooltip';

import DroppableWPCardList from './DroppableWPCardList';
import DroppableTaskCardList from './DroppableTaskCardList';
import { AddButton } from '../styled_components/AddButton';
import { InfoButton } from '../styled_components/InfoButton';
import { PinButton } from '../styled_components/PinButton';

import {
  handleMenu,
  dispatchUpdateViewState,
  setUserColleguePref,
  updateViewState,
  saveCurrentProject,
} from '../../data/actions/userInterface';
import ActionMenu from '../styled_components/ActionMenu';
import { PriorityIcon } from '../Icons/PriorityIcon';

import * as STATUS from '../../constants/status';
import * as NAV from '../../constants/navigation';
import * as TASK from '../../constants/tasks';
import * as utils from '../../utils/generalUtilities';

const assignedToAvatarSize = 30;

const useStyles = makeStyles(theme => ({
  column: {
    flex: 1,
    borderLeft: ({ isSelected, isSelectedColor }) =>
      `solid ${isSelected ? '3.5px' : '3.5px'} ${isSelectedColor}`,
    borderRight: ({ isSelected, isSelectedColor }) =>
      `solid ${isSelected ? '2.5px' : '2.5px'} ${isSelectedColor}`,
    // borderBottom: ({ isSelected, isSelectedColor }) =>
    //   `solid ${isSelected ? '3.0px' : '3.0px'} ${isSelectedColor}`,
    borderTop: ({ topColor }) => `solid 10px ${topColor}`,
    minWidth: '250px',
    maxWidth: '400px',
    marginLeft: '7px',
    marginRight: '8px',
    overflow: 'auto',
    width: '100%',
    cursor: 'pointer',
    minHeight: '70vh',
    background: theme.palette.workCard.wbsColumn,
    // maxHeight: ({ isExpanded }) => (isExpanded ? '80vh' : '60px'),
  },
  columnTop: {
    position: 'sticky',
    height: '8px',
    // background: 'red',
    background: ({ allTaskCardsDone, allTaskCardsNotStarted }) =>
      `${
        allTaskCardsNotStarted
          ? theme.palette.workCard.notStarted
          : allTaskCardsDone
          ? theme.palette.workCard.completed
          : theme.palette.workCard.started
      }`,
    //   return topColor

    // },
  },
  columnHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    position: 'sticky',
    alignItems: 'flex-start',
    top: 0,
    padding: '15px',
    background: theme.palette.workCard.wbsColumn,
    zIndex: 1,
  },

  titleContents: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    // backgroundColor: 'green'
  },
  titleContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    // backgroundColor: 'green'
  },
  lowerRightContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  addButtonContainer: {
    // marginTop:
    marginLeft: 5,
  },
  infoButton: {
    height: 14,
    alignSelf: 'flex-start',
    marginTop: -5,
    marginRight: 10,
    marginBottom: -5,
  },
  addWPButton: {
    height: 14,
    alignSelf: 'center',
    // backgroundColor: 'green'
    marginTop: -5,
    marginBottom: -5,
  },
  addButton: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    margin: '0 5px',
    '&:hover': {
      // background: theme.palette.secondary.light,
      color: theme.palette.secondary.dark,
    },
    '& svg': {
      fontSize: 22, // sets size of IconButton and scales the font inside
    },
    boxShadow: 1,
  },
  delTitle: {
    maxHeight: 50,
    overflow: 'hidden',
  },
  priorityIcon: {
    // marginTop: 4,
    // backgroundColor: 'green'
  },
  rightTitleComponents: {
    flex: 0,
    display: 'flex',
    marginTop: -2,
    marginLeft: 8,
    // position: 'absolute', // to parent as parent position is relative/sticky
    // paddingLeft: 10,
    // top: 12,
    // right: 5,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  colTitle: {
    fontSize: '1rem',
    fontWeight: '500',
    width: '100%',
  },
  subTitleRow: {
    display: 'flex',
    marginTop: -2,
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },

  assignedToAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: assignedToAvatarSize / 2,
    height: assignedToAvatarSize,
    width: assignedToAvatarSize,
    marginLeft: 4,
  },
  assignedToText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
  },
  tooltip: {
    fontSize: 20,
    // marginTop: 5,
    marginLeft: 4,
  },
  notAssignedTo: {
    color: '#A0A0A040',
  },
  numTasks: {
    fontSize: '.78rem',
  },
  dateString: {
    fontSize: '.8rem',
    alignSelf: 'flex-start',
  },
  endDate: {
    marginLeft: 15,
    alignSelf: 'flex-start',
    // backgroundColor: 'blue'
  },
  endDate2: {
    marginTop: -10,
    alignSelf: 'flex-start',
    // backgroundColor: 'blue'
  },
  pinButton: {
    marginTop: 4,
    marginLeft: 5,
  },
}));

let scrollTimer;

// Draggable container for workpackage header & WP card list
const DraggableWPColumn = ({
  wpCard,
  index,
  isExpanded,
  viewStack,
  viewState,
  handleMenu,
  dispatchUpdateViewState,
  setUserColleguePref,
  userPrefs,
  onboarding,
  updateViewState,
  saveCurrentProject,
  workflows,
}) => {
  const draggableId = wpCard.uuid;
  const title = wpCard.workpackage.name;
  const workpackage = wpCard.workpackage;
  const stackTop = viewStack[viewStack.length - 1];
  const isLibraryChange = viewState.curWindow === NAV.LIBRARY_BOARD;
  const inWorkflow = viewState.currentProjectType === 'Workflow';
  const isSelected =
    'd'.includes(stackTop.level) && stackTop.item.w === wpCard.index && stackTop.item.t === -1;
  let allTaskCardsDone = true;
  let allTaskCardsNotStarted = true;
  wpCard.taskCards.forEach(taskCard => {
    if (taskCard.task.actualStartDate !== '') allTaskCardsNotStarted = false;
    if (taskCard.task.actualEndDate === '') allTaskCardsDone = false;
  });
  const theme = useTheme();
  const [adviceComponent, setAdviceComponent] = React.useState(() => {
    return utils.getAdviceComponent(workflows, wpCard.workpackage.templateRefUUID, wpCard.workpackage);
  });
  const topColor = `${
    allTaskCardsNotStarted
      ? theme.palette.workCard.wbsColumnTopNotStarted
      : allTaskCardsDone
      ? theme.palette.workCard.wbsColumnTopCompleted
      : theme.palette.workCard.wbsColumnTopStarted
  }`;
  const isSelectedColor = `${
    allTaskCardsNotStarted
      ? theme.palette.workCard.wbsColumnBorderNotStarted
      : allTaskCardsDone
      ? theme.palette.workCard.wbsColumnBorderCompleted
      : theme.palette.workCard.wbsColumnBorderStarted
  }`;

  const classes = useStyles({ isExpanded, isSelected, topColor, isSelectedColor });
  const { currentWorkPackage } = viewState;
  const {
    percentageComplete,
    actualStartDate,
    isPriority,
    expectedStartDate,
    expectedEndDate,
    actualEndDate,
    assignedTo,
    numNonSkippedTasks,
    numDoneTasks,
    numInProgressItems,
    tasks,
    refId,
    name,
  } = workpackage;
  let MENU_ACTIONS = [
    {
      name: 'Open',
      action: `work package.${wpCard.parentCard.index}.${currentWorkPackage}.Open`,
      icon: <OpenInNewIcon />,
    },
    {
      name: 'Rename',
      action: `work package.${wpCard.parentCard.index}.${currentWorkPackage}.Edit`,
      icon: <EditIcon />,
    },
    {
      name: 'Assign',
      action: {
        open: {
          type: 'w',
          stack: viewStack,
        },
      },
      icon: <Person />,
    },
    {
      name: 'Details',
      action: {
        open: {
          type: 'w',
          stack: viewStack,
        },
      },
      icon: <EditIcon />,
    },
    {
      name: 'Delete',
      action: `work package.${wpCard.parentCard.index}.${currentWorkPackage}.Delete`,
      icon: <DeleteIcon />,
    },
    {
      name: 'Cut',
      action: `work package.${wpCard.parentCard.index}.${currentWorkPackage}.Cut`,
      icon: <FileCopyIcon />,
    },
    {
      name: 'Copy',
      action: `work package.${wpCard.parentCard.index}.${currentWorkPackage}.Copy`,
      icon: <FileCopyIcon />,
    },
    {
      name: 'Paste',
      action: `work package.${wpCard.parentCard.index}.${currentWorkPackage}.Paste`,
      icon: <FileCopyIcon />,
    },

    // {
    //   name: 'to Project',
    //   action: `workpackage.${currentWorkPackage}.ToProj`,
    //   icon: <PublishIcon />,
    //   tooltip: 'convert this task to a task group'
    // },
  ];
  if (!viewState.copyContent || viewState.copyType !== 'workPackage') MENU_ACTIONS.splice(7, 1); // remove paste option if no copy content
  if (wpCard.numSiblings === 1) MENU_ACTIONS.splice(5, 1); // don't allow cut of last workpackage
  // if (!inWorkflow && wpCard.status !== STATUS.IN_PROGRESS) {
  //   MENU_ACTIONS.push({
  //     name: wpCard.workpackage.pinned ? 'Unpin' : 'Pin',
  //     action: `workpackage.${currentWorkPackage}.Pin`,
  //     icon: wpCard.workpackage.pinned ? <VisibilityOffIcon /> : <VisibilityOffIcon />,
  //   });
  // }
  const handleDoubleClick = e => {
    if (wpCard.cardType !== TASK.WORKPACKAGE_CARD) return;
    console.log(`+++++++++++ double click in WP Col ...`);

    // alert(`column double clicked`)
    const newStack = viewStack.slice();
    const stackTop = newStack[newStack.length - 1];
    newStack.push({
      screen: NAV.BOARD_DETAIL,
      item: {
        d: stackTop.item.d,
        w: wpCard.index,
        t: -1,
      },
      level: 'w',
      zoomDelUID: stackTop.zoomDelUID,
    });
    Object.assign(viewState, {
      currentWorkPackage: wpCard.index,
      [viewStack === viewState.wbsStack ? 'wbsStack' : 'libStack']: newStack,
    });
    dispatchUpdateViewState(viewState);
  };
  const avatarId = assignedTo ? assignedTo.substring(0, 1).toUpperCase() : null;

  let newColorAssignment = null;
  useEffect(() => {
    if (newColorAssignment) {
      setUserColleguePref(newColorAssignment);
    }
  });
  let collegueColor;
  if (assignedTo) {
    if (userPrefs.collegues[assignedTo]) {
      collegueColor = userPrefs.collegues[assignedTo].color;
    } else {
      collegueColor = utils.randColor();
      newColorAssignment = {
        email: assignedTo,
        color: collegueColor,
      };
    }
  }

  let percentDone = 0;
  if (!workpackage.library) {
    percentDone = utils.percentageWorkpackageTasksDone(workpackage);
  }

  // let expectedEndDateOfNonDoneItems;
  // let expectedStartDateOfNonDoneItems;
  // if (tasks) {
  //   // to handle library editing
  //   tasks.forEach(task => {
  //     if (utils.isInProgressStatus(task.status)) {
  //       if (task.actualEndDate === '') {
  //         // if not done
  //         if (
  //           task.expectedStartDate !== '' &&
  //           (!expectedStartDateOfNonDoneItems ||
  //             moment(task.expectedStartDate).isBefore(moment(expectedStartDateOfNonDoneItems)))
  //         ) {
  //           expectedStartDateOfNonDoneItems = task.expectedStartDate;
  //         }
  //         if (
  //           task.expectedEndDate !== '' &&
  //           (!expectedEndDateOfNonDoneItems ||
  //             moment(task.expectedEndDate).isAfter(moment(expectedEndDateOfNonDoneItems)))
  //         ) {
  //           expectedEndDateOfNonDoneItems = task.expectedEndDate;
  //         }
  //       }
  //     }
  //   });
  //   if (!expectedStartDateOfNonDoneItems) expectedStartDateOfNonDoneItems = '';
  //   if (!expectedEndDateOfNonDoneItems) expectedEndDateOfNonDoneItems = '';
  // }
  const dateStats = utils.getDateStats(expectedEndDate);
  const { daysLeft, isLate, dueSoon } = dateStats;
  let { lateIconColor, tooltipTitle } = utils.getDateStatsInfo(isLate, dueSoon, daysLeft);

  // if not started - add exp start date with tooltip
  // if started - show exp end date (these get flagged if not set)
  // if done - show act end date
  const status =
    actualEndDate !== '' ? STATUS.DONE : actualStartDate !== '' ? STATUS.IN_PROGRESS : STATUS.NOT_STARTED;
  let itemDate = null;
  switch (status) {
    case STATUS.NOT_NEEDED:
      break;
    case STATUS.NOT_STARTED:
      // const startDateStats = utils.getDateStats(expectedStartDate);
      // const startDateStatsInfo = utils.getDateStatsInfo(
      //   startDateStats.isLate,
      //   startDateStats.dueSoon,
      //   startDateStats.daysLeft,
      //   false,
      // );
      itemDate = expectedStartDate !== '' ? `${moment(expectedStartDate).format('MM/DD/YYYY')}` : 'xx/xx/xx';
      tooltipTitle = expectedStartDate !== '' ? tooltipTitle : 'not scheduled';
      lateIconColor = lateIconColor;
      break;
    case STATUS.DONE:
      itemDate = actualEndDate !== '' ? `${moment(actualEndDate).format('MM/DD/YYYY')}` : '';
      tooltipTitle = 'actual end date';
      lateIconColor = '#444';
      break;
    case STATUS.IN_PROGRESS:
    case STATUS.IN_REVIEW:
    case STATUS.BLOCKED:
    default:
      const weekInFuture = moment(expectedEndDate).isAfter(moment().add(6, 'days'));

      itemDate = 'xx/xx/xx';
      tooltipTitle = 'not scheduled';
      lateIconColor = itemDate === 'xx/xx/xx' ? grey[500] : lateIconColor;
  }
  itemDate = null; // old, force off for now... can clean up later

  let numOpenTasks = numNonSkippedTasks - numDoneTasks;
  if (isNaN(numOpenTasks)) numOpenTasks = 0;
  const taskName = numOpenTasks === 1 ? 'task' : 'tasks';

  const emptyAdvice = adviceComponent ? utils.isInfoEmpty(adviceComponent.advice) : false;

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <Grid
          className={classes.column}
          ref={el => {
            if (el) {
              provided.innerRef(el);
              el.scrollTop =
                viewState.scrollTop.item && viewState.scrollTop.item[workpackage.uuid]
                  ? viewState.scrollTop.item[workpackage.uuid]
                  : 0;
              return el;
            }
          }}
          {...provided.draggableProps}
          onClick={() => {
            const newStack = viewStack.slice();
            const topStack = newStack[newStack.length - 1];
            newStack[newStack.length - 1].item = {
              // for now this is a no-op as the only place this is called is from a NAV.BOARD
              d: topStack.item.d,
              w: wpCard.index,
              t: -1,
            };
            Object.assign(viewState, {
              currentWorkPackage: wpCard.index,
              [viewStack === viewState.wbsStack ? 'wbsStack' : 'libStack']: newStack,
            });
            dispatchUpdateViewState(viewState);
          }}
          onScroll={e => {
            if (!viewState.scrollTop.item) viewState.scrollTop.item = {};
            if (e.currentTarget.scrollTop !== viewState.scrollTop.item[workpackage.uuid]) {
              if (scrollTimer) {
                clearTimeout(scrollTimer);
              }
              const scrollTop = e.currentTarget.scrollTop;
              scrollTimer = setTimeout(e => {
                // debounce scoll values
                viewState.scrollTop.item[workpackage.uuid] = scrollTop;
                // console.log(`scroll ${scrollTop}`);
                // updateViewState(viewState)
                scrollTimer = undefined;
              }, 50);
            }
          }}
        >
          <div
            className={classes.columnHeader}
            onDoubleClick={handleDoubleClick}
            {...provided.dragHandleProps}
          >
            <div className={classes.titleContents}>
              <div className={classes.titleContainer}>
                <div className={classes.delTitle}>
                  <Typography className={classes.colTitle}>{title}</Typography>
                </div>
                {wpCard.cardType === TASK.WORKPACKAGE_CARD && (
                  <div className={classes.rightTitleComponents}>
                    {adviceComponent && (inWorkflow || !emptyAdvice) && (
                      <InfoButton
                        handleClick={() => {
                          const newInfoDialogData = {
                            name: `Task Group: ${wpCard.workpackage.name}`,
                            text: adviceComponent.advice,
                            label: `Advice for the Task Group: ${wpCard.workpackage.name}`,
                          };
                          if (inWorkflow) {
                            newInfoDialogData.onSave = newText => {
                              adviceComponent.advice = newText;
                              // console.log(`changed advice DelCol - ${newText.blocks[0].text}`)
                              // hack to reload adviceComponent because it may have changed since first fetch
                              const latestDel = utils.getAdviceComponent(
                                workflows,
                                wpCard.workpackage.templateRefUUID,
                                wpCard.workpackage,
                              );
                              latestDel.advice = newText;
                              saveCurrentProject();
                            };
                          }
                          viewState = Object.assign(viewState, { infoDialogData: newInfoDialogData });
                          updateViewState(viewState);
                        }}
                        size="small"
                        color={theme.palette.primary.infoButton}
                        bgdColor="#FFF0"
                        hoverColor={theme.palette.primary.infoButton + '10'}
                        borderColor={theme.palette.primary.infoButton}
                        tooltip={inWorkflow ? (emptyAdvice ? 'add help' : 'edit help') : 'Help'}
                        containerClassName={classes.infoButton}
                        type={'a'}
                      />
                    )}
                    {isPriority && (
                      <div className={classes.priorityIcon}>
                        <PriorityIcon />
                      </div>
                    )}
                    {percentDone > 0 && (
                      <Typography variant="overline">
                        {workpackage && `${percentDone.toFixed(0)}%`}
                      </Typography>
                    )}
                    {avatarId ? (
                      <Tooltip title={assignedTo} className={classes.tooltip}>
                        <div className={classes.assignedToAvatar} style={{ backgroundColor: collegueColor }}>
                          <Typography className={classes.assignedToText}>{avatarId}</Typography>
                        </div>
                      </Tooltip>
                    ) : (
                      <div className={classes.assignedToAvatar} style={{ backgroundColor: '#A0A0A020' }}>
                        <Person className={classes.notAssignedTo} />
                      </div>
                    )}
                    {isSelected && (
                      <ActionMenu handleMenu={handleMenu} actions={MENU_ACTIONS} viewStack={viewStack} />
                    )}
                  </div>
                )}
              </div>
              {
                <div className={classes.subTitleRow}>
                  {/* <div>
                    <Typography variant="overline" className={classes.numTasks}>
                      {numOpenTasks === 0 ? 'completed' : `${numOpenTasks} ${taskName}`}
                    </Typography>
                  </div> */}
                  <div className={classes.lowerRightContainer}>
                    <div className={classes.addButtonContainer}>
                      <AddButton
                        handleClick={() => {
                          let curTaskIndex;
                          let location;
                          const stackTop = isLibraryChange
                            ? viewState.libStack[viewState.libStack.length - 1]
                            : viewState.wbsStack[viewState.wbsStack.length - 1];
                          curTaskIndex = stackTop.item.t;
                          location = '';
                          switch (wpCard.cardType) {
                            default:
                            case TASK.WORKPACKAGE_CARD:
                              if (
                                stackTop.item.w !== wpCard.index ||
                                curTaskIndex === undefined ||
                                curTaskIndex < 0
                              ) {
                                curTaskIndex = 0;
                                location = '.ABOVE';
                              }
                              handleMenu(`task.${curTaskIndex}.Add`);
                              break;
                            case TASK.LIB_TASK_CONTAINER_CARD:
                              // in tasks library column
                              curTaskIndex = stackTop.item.w;
                              location = '';
                              if (curTaskIndex === undefined || curTaskIndex < 0) {
                                curTaskIndex = 0;
                                location = '.ABOVE';
                              }
                              handleMenu(`task.${curTaskIndex}.Add${location}`);
                              break;
                            case TASK.LIB_WP_CONTAINER_CARD:
                              let curDelIndex = stackTop.item.d;
                              if (curDelIndex === undefined || curDelIndex < 0) {
                                curDelIndex = 0;
                              }
                              curTaskIndex = stackTop.item.w;
                              location = '';
                              if (curTaskIndex === undefined || curTaskIndex < 0) {
                                curTaskIndex = 0;
                                location = '.ABOVE';
                              }
                              handleMenu(`work package.${curDelIndex}.${curTaskIndex}.Add${location}`);
                              break;
                          }
                        }}
                        size="small"
                        color="#FFF"
                        bgdColor={theme.palette.primary.addButton}
                        hoverColor={theme.palette.secondary.addButton + 'E0'}
                        borderColor="#FFF"
                        tooltip={'add a new task'}
                        containerClassName={classes.addWPButton}
                      />
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <DroppableTaskCardList listId={wpCard.uuid} taskCards={wpCard.taskCards} viewStack={viewStack} />
        </Grid>
      )}
    </Draggable>
  );
};

export default connect(
  state => {
    return {
      projects: state.projects,
      workflows: state.workflows,
      viewState: state.viewState,
      userPrefs: state.userPrefs,
    };
  },
  {
    handleMenu,
    dispatchUpdateViewState,
    setUserColleguePref,
    updateViewState,
    saveCurrentProject,
  },
)(DraggableWPColumn);

import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  saveCurrentProject,
  handleCardDrop,
  toggleNotNeededTaskVisibility,
  toggleSortTasks,
} from '../../data/actions/userInterface';
import { Formik } from 'formik';
import moment from 'moment';
import Switch from '@material-ui/core/Switch';

import { ContentWrapper } from '../styled_components/ContentWrapper';
import StatusButton from '../styled_components/StatusButton';
import { ComponentListWrapper } from './ComponentListWrapper';
import ItemDetails from './ItemDetails';
import * as utils from '../../utils/generalUtilities.js';
// import Button from '@material-ui/core/Button';
import DraftEditor from '../styled_components/DraftEditor';
import { red, yellow, grey } from '@material-ui/core/colors';
import { DateRange } from '@material-ui/icons';
import * as NAV from '../../constants/navigation';
import { DragDropContext } from 'react-beautiful-dnd';
import DroppableWPCardList from '../project_deliverables/DroppableWPCardList';
import DroppableTaskCardList from '../project_deliverables/DroppableTaskCardList';
import { ExpandButton } from '../styled_components/ExpandButton';
import { updateViewState } from '../../data/actions/userInterface';
import OnboardingDialog from '../OnboardingDialog';
import { AttachmentsTable } from '../attachments/AttachmentsTable';

import {
  dispatchUpdateViewState,
  handleMenu,
  dispatchSplitWorkPackage,
} from '../../data/actions/userInterface';
import { toggleDetailPinVisibility } from '../../data/actions/app';
import { Typography, Button, Tooltip, makeStyles, useTheme } from '@material-ui/core';
import * as TASK from '../../constants/tasks';
import * as STATUS from '../../constants/status.js';
import * as USER from '../../constants/user';

const detailStyles = makeStyles(theme => ({
  splitSectionWrapper: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    gridColumnGap: '2vw',
    marginTop: '20px',
  },
  editorWrapper: {
    overflowY: 'hidden',
    marginRight: '20px',
    // marginBottom: '200px',
  },
  tasksWrapper: {
    overflowY: 'scroll',
    height: ({ pixelRatio }) => `calc(70vh - 80px)`,
    marginBottom: ({ pixelRatio }) => `${Math.round(120)}px`,
    // marginBottom: '100px',
    // maxHeight: '65vh',
    // maxHeight: 'calc(100vh - 320px)',
  },
  // threeColumn: {
  //   flex: 1,
  //   // maxWidth: 600,
  //   // maxWidth: 'calc(100% - 530px)',
  //   width: '100%',
  //   alignSelf: 'stretch',
  //   display: 'flex',
  //   flexDirection: 'row',
  //   justifyContent: 'flex-start',
  //   overflowY: 'hidden',

  //   backgroundColor: 'yellow'
  // },
  threeColumn: {
    position: 'absolute',
    top: '160px',
    left: '90px',
    right: '80px',
    bottom: '30px',
    zIndex: 10,
    flex: 1,
    // maxWidth: 600,
    // maxWidth: 'calc(100% - 530px)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    overflowY: 'hidden',
    backgroundColor: 'white',
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  column1: {
    // flex: 1,
    display: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '250px',
    // paddingTop: '15px',
  },

  column2: {
    flex: 2,
    display: 'block',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 'calc(100% - 60px)',
    marginLeft: 5,
    marginBottom: 20,
    overflowY: 'hidden',
  },
  column2Collapsed: {
    display: 'block',
    // marginRight: 2,
    // paddingRight: 15,
    height: 'calc(100% - 60px)',
    marginLeft: 0,
    overflowY: 'hidden',
  },
  column3: {
    flex: 3,
    display: 'block',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: ({ notesExpanded }) => (notesExpanded ? 20 : 0),
    marginLeft: 0,
    marginBottom: 20,
    overflowY: 'hidden',
    // height: '40vh'
  },
  headerBar: {
    marginLeft: '0px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    borderBottom: `solid 3px #888`,
    height: 35,
    backgroundColor: theme.palette.secondary.main,
    alignItems: 'center',
    paddingBottom: -4,
  },
  addIcon: {
    color: 'white',
    borderWidth: 2,
    borderColor: 'white',
    // fontSize: 16,
  },
  lateIcon: {
    alignSelf: 'center',
    marginLeft: 10,
  },
  title: {
    color: theme.palette.secondary.main,
    fontWeight: 550,
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  notesTitle: {
    color: '#FFF',
    fontSize: 18,
    fontWeight: 700,
    textTransform: 'none',
    marginLeft: '10px',
  },
  expandButton: {
    height: 14,
    alignSelf: 'flex-start',
    marginTop: 5,
    marginRight: ({ notesExpanded }) => (notesExpanded ? 0 : 2),
    marginLeft: 3,
  },
  switch: {
    inputProps: { 'aria-label': 'show/hide hidden items' },
  },
}));
const buttonStyles = makeStyles(theme => ({
  colorBase: {
    color: ({ buttonColor }) => theme.palette.getContrastText(buttonColor),
    backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    // '&:hover': {
    //   backgroundColor: ({buttonColor}) => `${buttonColor}`,
    // },
    width: '120px',
    marginRight: '10px',
  },
}));
let scrollTimer;

const DetailsSection = ({
  viewCard,
  viewStack,
  parent,
  viewState,
  userPrefs,
  saveCurrentProject,
  dispatchUpdateViewState,
  handleMenu,
  handleCardDrop,
  toggleNotNeededTaskVisibility,
  toggleSortTasks,
  updateViewState,
  toggleDetailPinVisibility,
  project,
  community,
  sprintData,
  dispatchSplitWorkPackage,
}) => {
  const theme = useTheme();
  const pixelRatio = window.devicePixelRatio / 2.0;
  const classes = detailStyles({ notesExpanded: viewState.notesExpanded, pixelRatio });

  let onboardingTitle;
  let onboardingLink;
  let onboardingKey;
  let onboardingDesc;
  if (viewCard.cardType === TASK.DELIVERABLE_CARD) {
    onboardingTitle = `You have opened a Deliverable`;
    onboardingDesc = [
      'View or edit the Deliverable description',
      'The status is rolled up from the Task Groups',
    ];
    onboardingLink = null;
    // onboardingLink = 'https://www.youtube.com/watch?v=mHivSHo-_0c';
    onboardingKey = USER.ONBOARDING_DELIVERABLE_DIALOG;
  } else {
    if (viewCard.cardType === TASK.WORKPACKAGE_CARD) {
      onboardingTitle = `You have opened a Task Group`;
      onboardingDesc = [
        'View or edit the Task Group on this screen.',
        'Manage all tasks within the group and capture a description for the group',
        'The status is rolled up from the tasks.',
        'If needed, you can easily split a Task Group into two using the button in the upper right.',
      ];
      onboardingLink = null;
      // onboardingLink = 'https://www.youtube.com/watch?v=-1DNfLjN-QM';
      onboardingKey = USER.ONBOARDING_WORKPACKAGE_DIALOG;
    } else {
      onboardingTitle = `The Task Details Screen`;
      onboardingDesc = ['View or edit the task description on this screen.', 'Task status is rolled up'];
      onboardingLink = null;
      // onboardingLink = 'https://www.youtube.com/watch?v=xNX41EgIG5g';
      onboardingKey = USER.ONBOARDING_TASK_DIALOG;
    }
  }
  const [attachmentsComponent, setAttachmentsComponent] = React.useState(null);

  const [onboardingDialog, setOnboardingDialog] = React.useState(
    Boolean(userPrefs.onboarding && userPrefs.onboarding[onboardingKey]),
  );
  useEffect(() => {
    setOnboardingDialog(Boolean(userPrefs.onboarding && userPrefs.onboarding[onboardingKey]));
  }, [onboardingKey, userPrefs.onboarding]);

  const onDragEnd = result => {
    console.log(`drag ended - do something`);
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    handleCardDrop(
      source,
      destination,
      viewStack[viewStack.length - 1].level,
      viewState,
      parent === 'Library',
    );
  };

  const handleChange = (_event, newValue) => {
    if (newValue !== viewState.detailsTabIndex) {
      Object.assign(viewState, { detailsTabIndex: newValue });
      dispatchUpdateViewState(viewState); // store tab index in redux store allows global detection of user action (tap on tab)
    }
  };

  let value = viewState.detailsTabIndex;
  if (viewCard.cardType === TASK.TASK_CARD && value > 1) value = 1;

  const getItem = () => {
    const cardContent =
      viewCard.cardType === TASK.TASK_CARD
        ? viewCard.task
        : viewCard.cardType === TASK.WORKPACKAGE_CARD
        ? viewCard.workpackage
        : viewCard.deliverable;
    const {
      percentageComplete,
      actualStartDate,
      expectedEndDate,
      actualEndDate,
      numInProgressItems,
    } = cardContent;

    const showPercentage = !isNaN(percentageComplete) && percentageComplete >= 0 && actualEndDate === '';

    let itemSprintId = utils.date2SprintId(expectedEndDate, viewState.numWeeksInSprint);
    let sprintOffset = utils.getSprintOffset(itemSprintId, null, viewState.numWeeksInSprint);
    const [sprintStartDate, sprintEndDate] = utils.getSprintHumanDates(
      itemSprintId,
      viewState.numWeeksInSprint,
    );
    const dateStats = utils.getDateStats(sprintEndDate);

    const { daysLeft, isLate, dueSoon } = dateStats;
    let { lateIconColor, tooltipTitle } = utils.getDateStatsInfo(isLate, dueSoon, daysLeft);

    const status =
      actualEndDate !== '' ? STATUS.DONE : actualStartDate !== '' ? STATUS.IN_PROGRESS : STATUS.NOT_STARTED;
    const buttonColor = utils.getStatusColor(status);
    const buttonClasses = buttonStyles({ buttonColor });

    const needsDueDate = actualStartDate !== '' && !expectedEndDate;
    const showCalIcon = sprintEndDate && actualEndDate === '' && (isLate || dueSoon || needsDueDate);

    const isLibrary = viewState.prevWindow === NAV.LIBRARY_BOARD;

    switch (viewCard.cardType) {
      case TASK.TASK_CARD:
        return {
          item: viewCard.task,
          editStr: `task.${viewCard.index}.Edit`,
          title: 'Task',
          listTitle: '',
          activeItemCount: null,
          itemList: null,
          statusComponent: <StatusButton taskCard={viewCard} />,
          numTabs: 2,
          editStartDate: true,
          editEndDate: true,
        };
      case TASK.WORKPACKAGE_CARD:
        return {
          item: viewCard.workpackage,
          editStr: `work package.${viewCard.parentCard.index}.${viewCard.index}.Edit`,
          title: 'Task Group',
          listTitle: 'Tasks',
          activeItemCount: viewCard.workpackage.tasks.reduce((acc, cur) => {
            if (cur.status === STATUS.NOT_NEEDED || cur.status === STATUS.DONE) return acc;
            else return acc + 1;
          }, 0),
          itemList: (
            <ComponentListWrapper
              className={classes.listWrapper}
              value={value}
              index={1}
              viewStack={viewStack}
              viewState={viewState}
              showButtons={true}
              card={viewCard}
              handleMenu={handleMenu}
              notNeededTaskCount={utils.numNotNeededTasks(viewCard.workpackage)}
              toggleNotNeededTaskVisibility={toggleNotNeededTaskVisibility}
              toggleSortTasks={toggleSortTasks}
              thinBar={false}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <div
                  className={classes.tasksWrapper}
                  ref={r => {
                    if (r) {
                      r.scrollTop =
                        viewState.scrollTop.itemDetail &&
                        viewState.scrollTop.itemDetail[viewCard.workpackage.uuid]
                          ? viewState.scrollTop.itemDetail[viewCard.workpackage.uuid]
                          : 0;
                    }
                  }}
                  onScroll={e => {
                    if (!viewState.scrollTop.itemDetail) viewState.scrollTop.itemDetail = {};
                    if (
                      e.currentTarget.scrollTop !== viewState.scrollTop.itemDetail[viewCard.workpackage.uuid]
                    ) {
                      if (scrollTimer) {
                        clearTimeout(scrollTimer);
                      }
                      const scrollTop = e.currentTarget.scrollTop;
                      scrollTimer = setTimeout(e => {
                        // debounce scoll values
                        viewState.scrollTop.itemDetail[viewCard.workpackage.uuid] = scrollTop;
                        // console.log(
                        //   `scroll ${viewCard.workpackage.detailScrollTop}`,
                        // );
                        // updateViewState(viewState)
                        scrollTimer = undefined;
                      }, 50);
                    }
                  }}
                >
                  <DroppableTaskCardList
                    listId={viewCard.workpackage.uuid}
                    taskCards={viewCard.taskCards}
                    // thin={false}
                    thin={true}
                    viewStack={viewStack}
                    isLibrary={parent === 'Library'}
                  />
                </div>
              </DragDropContext>
            </ComponentListWrapper>
          ),
          statusComponent: (
            <div className={classes.statusContainer}>
              <Button
                size={'small'}
                onClick={e => {
                  e.stopPropagation();
                }}
                // disabled={true}
                variant="contained"
                className={buttonClasses.colorBase}
              >
                {status}
              </Button>
              {showPercentage && (
                <Typography variant="overline">{`${percentageComplete.toFixed(0)}%`}</Typography>
              )}
              {showCalIcon && (
                <div className={classes.lateIcon}>
                  <Tooltip title={tooltipTitle}>
                    <DateRange style={{ color: lateIconColor }} />
                  </Tooltip>
                </div>
              )}
            </div>
          ),
          numTabs: 3,
          editStartDate: utils.subcomponetsHaveNoDateSet(viewCard.workpackage, 'start', 'W'),
          editEndDate: utils.subcomponetsHaveNoDateSet(viewCard.workpackage, 'end', 'W'),
        };
      case TASK.DELIVERABLE_CARD:
        return {
          item: viewCard.deliverable,
          editStr: `deliverable.${viewCard.index}.Edit`,
          title: 'Deliverable',
          listTitle: 'Task Groups',
          activeItemCount: viewCard.deliverable.workPackages.reduce((acc, cur) => {
            if (cur.actualEndDate !== '') return acc;
            else return acc + 1;
          }, 0),
          itemList: (
            <ComponentListWrapper
              className={classes.listWrapper}
              value={value}
              index={1}
              viewStack={viewStack}
              viewState={viewState}
              showButtons={true}
              card={viewCard}
              handleMenu={handleMenu}
              thinBar={false}
            >
              <div
                className={classes.tasksWrapper}
                ref={r => {
                  if (r) {
                    r.scrollTop =
                      viewState.scrollTop.itemDetail &&
                      viewState.scrollTop.itemDetail[viewCard.deliverable.uuid]
                        ? viewState.scrollTop.itemDetail[viewCard.deliverable.uuid]
                        : 0;
                  }
                }}
                onScroll={e => {
                  if (!viewState.scrollTop.itemDetail) viewState.scrollTop.itemDetail = {};
                  if (
                    e.currentTarget.scrollTop !== viewState.scrollTop.itemDetail[viewCard.deliverable.uuid]
                  ) {
                    if (scrollTimer) {
                      clearTimeout(scrollTimer);
                    }
                    const scrollTop = e.currentTarget.scrollTop;
                    scrollTimer = setTimeout(e => {
                      // debounce scoll values
                      viewState.scrollTop.itemDetail[viewCard.deliverable.uuid] = scrollTop;
                      scrollTimer = undefined;
                    }, 50);
                  }
                }}
              >
                <DragDropContext onDragEnd={onDragEnd}>
                  <DroppableWPCardList
                    listId={viewCard.deliverable.uuid}
                    wpCards={viewCard.wpCards}
                    thin={true}
                    viewStack={viewStack}
                    isLibrary={parent === 'Library'}
                    parentContext={NAV.BOARD}
                  />
                </DragDropContext>
              </div>
            </ComponentListWrapper>
          ),
          statusComponent: (
            <div className={classes.statusContainer}>
              <Button
                size={'small'}
                onClick={e => {
                  e.stopPropagation();
                }}
                variant="contained"
                className={buttonClasses.colorBase}
              >
                {status}
              </Button>
              {showPercentage && (
                <Typography variant="overline">{`${percentageComplete.toFixed(0)}%`}</Typography>
              )}
              {showCalIcon && (
                <div className={classes.lateIcon}>
                  <Tooltip title={tooltipTitle} className={classes.lateIcon}>
                    <DateRange style={{ color: lateIconColor }} />
                  </Tooltip>
                </div>
              )}
            </div>
          ),
          numTabs: 3,
          editStartDate: utils.subcomponetsHaveNoDateSet(viewCard.deliverable, 'start', 'D'),
          editEndDate: utils.subcomponetsHaveNoDateSet(viewCard.deliverable, 'end', 'D'),
        };
      default:
        console.error(`invalid cardType in DetailsSection: ${viewCard.cardType}`);
    }
  };
  let {
    item,
    editStr,
    title,
    listTitle,
    itemList,
    numTabs,
    statusComponent,
    activeItemCount,
    editStartDate,
    editEndDate,
  } = getItem();

  if (!item) return null; // temp trap to handle side-effect of deleting prev "selectedItem" item or container

  // if (attachmentsComponent && item.uuid !== attachmentsComponent) {
  //   setTimeout(() => {
  //     setAttachmentsComponent(null);
  //   },0)
  // }
  const handleSave = ({ description }) => {
    // let { item } = getItem();
    if (item) {
      Object.assign(item, { description });
      saveCurrentProject();
    }
  };

  const handleSaveGuidance = ({ help }) => {
    // let { item } = getItem();
    if (item) {
      Object.assign(item, { help });
      saveCurrentProject();
    }
  };

  const expandButton = (
    <ExpandButton
      handleClick={() => {
        if (viewState.notesExpanded) {
          viewState = Object.assign(viewState, { notesExpanded: false });
        } else {
          viewState = Object.assign(viewState, { notesExpanded: true });
        }
        updateViewState(viewState);
      }}
      size="small"
      color={theme.palette.secondary.main}
      bgdColor={theme.palette.primary.expandButton}
      hoverColor={'#FFF8'}
      borderColor="#FFF0"
      // color={theme.palette.primary.expandButton}
      // bgdColor="#FFF0"
      // hoverColor={theme.palette.primary.expandButton + '10'}
      // borderColor={theme.palette.primary.expandButton}
      tooltip={viewState.notesExpanded ? 'hide description' : 'show description'}
      containerClassName={classes.expandButton}
      notesExpanded={viewState.notesExpanded}
    />
  );
  const cardTitle = !viewCard
    ? ''
    : viewCard.cardType === TASK.DELIVERABLE_CARD
    ? 'Deliverable'
    : viewCard.cardType === TASK.WORKPACKAGE_CARD
    ? 'Task Group'
    : 'Task';

  const inWorkflow = viewState.currentProjectType === 'Workflow';

  const toggleAttachmentsOpen = () => {
    setAttachmentsComponent(attachmentsComponent ? null : item.uuid);
  };
  const containerHeight = window.innerHeight - 180;
  const editorHeight = containerHeight - 80;
  // // const pr = window.devicePixelRatio;
  // const pr = window.devicePixelRatio;
  // // console.log(`inner height: ${window.innerHeight}`);
  // const containerHeight = Math.floor(window.innerHeight - 200) * 0.85;
  // const editorHeight = containerHeight ;
  const innerEditorHeight = editorHeight - 80;
  // console.log(`heights: ${editorHeight} ${window.innerHeight}`);
  return (
    <div
      style={{
        position: 'absolute',
        top: '66px',
        bottom: 0,
        right: 0,
        left: 50,
        maxHeight: 'calc(100vh -  66px)',
        overflow: 'hidden',
        backgroundColor: '#BBB6',
        // backgroundColor: 'red',
        zIndex: 20,
      }}
    >
      <div
        style={{
          margin: '30px',
          padding: '10px',
          paddingBottom: '30px',
          marginLeft: '60px',
          marginRight: '50px',
          border: '8px solid #BBB',
          height: 'calc(100vh - 160px)',
          overflow: 'hidden',
          borderRadius: '15px',
          backgroundColor: 'white',
          flex: 1,
        }}
      >
        <ContentWrapper
          refId={item.refId}
          title={item.name}
          titleChildren={
            <Fragment>
              {!inWorkflow && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'flex-end',
                    height: '40px',
                    marginLeft: '10px',
                    marginTop: '20px',
                    minWidth: '180px',
                  }}
                >
                  {viewCard.cardType === TASK.WORKPACKAGE_CARD && (
                    <Tooltip title={'split the task group'}>
                      <Button
                        size={'small'}
                        variant="contained"
                        color="primary"
                        onClick={e => {
                          dispatchSplitWorkPackage(viewCard.parentCard.deliverable, viewCard.workpackage);
                          e.stopPropagation();
                        }}
                        className={classes.splitButton}
                      >
                        Split this Task Group
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip
                    title={viewState.showDetailUnpinned ? 'showing all items' : 'hiding unpinned items'}
                  >
                    <Switch
                      className={classes.switch}
                      checked={viewState.showDetailUnpinned}
                      onChange={e => {
                        toggleDetailPinVisibility();
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </Fragment>
          }
          isSmall
          headerWidth={window.innerWidth - 150}
          toggleAttachmentsOpen={toggleAttachmentsOpen}
          attachmentState={!!attachmentsComponent}
          badge={item.attachmentLinks?.length}
          backToWindow={viewState.prevWindow || NAV.BOARD}
          // backToWindow={parent === 'WBS' ? NAV.BOARD : NAV.LIBRARY_BOARD}
          backCallback={() => {
            setAttachmentsComponent(null);
          }}
          viewStack={viewStack}
          detailEditStr={editStr}
          viewState={viewState}
          dispatchUpdateViewState={dispatchUpdateViewState}
          handleMenu={handleMenu}
          viewCard={viewCard}
        >
          <div className={classes.threeColumn}>
            <div className={classes.column1}>
              {statusComponent}
              <Formik
                enableReinitialize
                render={props => (
                  <ItemDetails
                    {...props}
                    item={item}
                    cardType={viewCard.cardType}
                    rerenderChange={new Date().getTime()}
                    editStartDate={editStartDate}
                    editEndDate={editEndDate}
                    project={project}
                    community={community}
                    sprintData={sprintData}
                  />
                )}
                initialValues={item}
                validationSchema={utils.projectInfoValidationSchema}
              />
            </div>
            {!!attachmentsComponent ? (
              <AttachmentsTable component={item} />
            ) : (
              <Fragment>
                {viewState.notesExpanded || !itemList ? (
                  <div className={classes.column2}>
                    <div className={classes.headerBar}>
                      {itemList && expandButton}
                      <Typography component="p" variant="overline" className={classes.notesTitle}>
                        {`Description of ${cardTitle}`}
                      </Typography>
                    </div>
                    <div className={classes.editorWrapper}>
                      <DraftEditor
                        key={item.uuid}
                        value={item.description}
                        placeholder={'Add a description here'}
                        field={`description`}
                        fieldSuffix={`${item.uuid}`}
                        onSave={handleSave}
                        flex={0}
                        // maxHeight={`${innerEditorHeight - 10}px`}
                        fixedHeight={`${innerEditorHeight - 20}px`}
                        maxHeightOuter={editorHeight}
                        restrictedToolbar={viewCard.cardType === TASK.TASK_CARD ? 2 : 1}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={classes.column2Collapsed}>
                    <div className={classes.headerBar}>{expandButton}</div>
                  </div>
                )}
                {itemList && <div className={classes.column3}>{itemList}</div>}
              </Fragment>
            )}
          </div>
          {onboardingDialog && (
            <OnboardingDialog
              title={onboardingTitle}
              text={[...onboardingDesc]}
              links={[{ text: 'here', link: onboardingLink }]}
              // text={"When you log in the Dashboard displays...\nPlease select the  page video now\nGo back to '?' anytime"}
              onboardingKey={onboardingKey}
              closeDialog={setOnboardingDialog}
              textOffset={'70px'}
            />
          )}
        </ContentWrapper>
      </div>
    </div>
  );
};

export default connect(
  state => {
    return {
      viewState: state.viewState,
      userPrefs: state.userPrefs,
    };
  },
  {
    handleMenu,
    saveCurrentProject,
    dispatchUpdateViewState,
    handleCardDrop,
    toggleNotNeededTaskVisibility,
    toggleSortTasks,
    updateViewState,
    toggleDetailPinVisibility,
    dispatchSplitWorkPackage,
  },
)(DetailsSection);
